import { LocaleContext } from 'index';
import React from 'react';

interface WithLocaleProps {}

const withLocale = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithLocaleProps> => ({
  ...props
}: WithLocaleProps) => {
  return (
    <LocaleContext.Consumer>{({ locale }) => <Component {...(props as P)} locale={locale} />}</LocaleContext.Consumer>
  );
};
export default withLocale;
