// App
import App from 'app';

// CSS
import 'react-perfect-scrollbar/dist/css/styles.css';

// FormatJS
import { IntlProvider } from 'react-intl';

// DOM
import ReactDOM from 'react-dom';

// Externals
import { SnackbarProvider } from 'notistack';
import { FirebaseAppProvider } from 'reactfire';
import { BrowserRouter } from 'react-router-dom';

// Firebase
import 'firebase/auth';
import 'firebase/firestore';

// Material
import { ThemeProvider } from '@material-ui/core';

// Material CSS
import CssBaseline from '@material-ui/core/CssBaseline';

// Material Pickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Messages
import french_messages from 'i18n/fr.json';
import dutch_messages from 'i18n/nl.json';

// Moment
import 'moment/locale/fr';
import 'moment/locale/nl';
import moment from 'moment';

// Services
import { unregister } from 'shared/services/serviceWorker';

// Styles
import GlobalStyles from 'app/styles/global';

// Theme
import MuiTheme from 'app/theme';

// Tools
import reportWebVitals from 'shared/tools/reportWebVitals';

// Utils
import MomentUtils from '@date-io/moment';
import React from 'react';
import LocalizedApp, { WithLocaleProps } from 'i18n/localized_app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

export const LocaleContext = React.createContext({
  locale: 'fr',
  setLocale: (locale: string) => {}
});

const messages = {
  fr: french_messages,
  nl: dutch_messages
};

ReactDOM.render(
  <LocalizedApp>
    {({ locale, setLocale }: WithLocaleProps) => {
      if (locale === 'fr') {
        moment.locale('fr');
      } else if (locale === 'nl') {
        moment.locale('nl');
      }
      return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
          <IntlProvider
            messages={(messages as any)[locale]}
            locale={locale}
            key={locale}
            onError={(err) => {
              if (err.code === 'MISSING_TRANSLATION') {
                console.warn('Missing translation', err.message);
                return;
              }
              throw err;
            }}
          >
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                maxSnack={3}
                preventDuplicate
              >
                <ThemeProvider theme={MuiTheme}>
                  <CssBaseline />
                  <GlobalStyles />
                  <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </SnackbarProvider>
            </FirebaseAppProvider>
          </IntlProvider>
        </LocaleContext.Provider>
      );
    }}
  </LocalizedApp>,
  document.getElementById('root')
);

// Learn more about moment : https://momentjscom.readthedocs.io/en/latest/moment/00-use-it/09-typescript/
moment.locale();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
