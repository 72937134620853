import { FC, useEffect, useState } from 'react';

// Externals
import clsx from 'clsx';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import * as yup from 'yup';
import firebase from 'firebase';

// Material
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  makeStyles
} from '@material-ui/core';

// Material Pickers
import { DatePicker } from '@material-ui/pickers';

// Models
import { TimelineLimitsDto } from 'app/models/timeline_limits.dto';
import moment from 'moment';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

interface Props {
  className?: string;
}

interface States {
  from: Date;
  to: Date;
}

const TimelineLimits: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const timelineLimitsRef = useFirestore().collection('settings2023').doc('timelineLimits');

  const { status, data, hasEmitted } = useFirestoreDocData<TimelineLimitsDto>(timelineLimitsRef, {
    initialData: {
      from: 0,
      to: 0
    }
  });

  const [initialValues, setInitialValues] = useState<States>({
    from: moment().add(-5, 'month').toDate(),
    to: moment().add(-1, 'month').toDate()
  });

  const onSubmit = async (values: States, actions: FormikHelpers<States>) => {
    try {
      const timelineLimits: TimelineLimitsDto = {
        from: firebase.firestore.Timestamp.fromDate(values.from),
        to: firebase.firestore.Timestamp.fromDate(values.to)
      };

      await timelineLimitsRef.set(timelineLimits, { merge: true });

      const key = enqueueSnackbar(`Les limites de la timeline ont été sauvegardées avec succès !`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        }
      });
    } catch (error) {
      console.error(error);
      const key = enqueueSnackbar(
        `Les limites de la timeline n'ont pas pu être sauvegardées. Si le problème persiste, veuillez contacter votre support technique.`,
        {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(data))
      setInitialValues({
        from: !data.from || data.from.toMillis() === 0 ? moment().add(-5, 'month').toDate() : data.from.toDate(),
        to: !data.to || data.to.toMillis() === 0 ? moment().add(-1, 'month').toDate() : data.to.toDate()
      });
  }, [data]);

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={yup.object().shape({
        from: yup.date().required(`La date de début est nécessaire.`),
        to: yup.date().required(`La date de fin est nécessaire.`)
      })}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        setFieldError,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        values,
        isSubmitting,
        isValid
      }) => (
        <form onSubmit={handleSubmit}>
          <Card className={clsx(classes.root, className)}>
            <CardHeader
              subheader={
                status === 'loading' || !hasEmitted
                  ? 'Chargement...'
                  : "Gérer la date de début et de fin pour l'affichage de la ligne du temps"
              }
              title="Limites de la timeline"
            />
            <Divider />
            {(status === 'loading' || !hasEmitted || isSubmitting) && <LinearProgress color="secondary" />}
            <CardContent>
              <Grid container spacing={2} wrap="wrap">
                <Grid className={classes.item} item sm={6} xs={12}>
                  <DatePicker
                    autoOk={true}
                    fullWidth={true}
                    label="Mois de début"
                    margin="dense"
                    name="from"
                    error={Boolean(touched.from && errors.from)}
                    helperText={touched.from && errors.from}
                    onChange={(month) => {
                      if (month) {
                        setFieldValue('from', new Date(month.year(), month.month()));
                      }
                      setFieldTouched('from', month?.toDate() !== initialValues.from);
                    }}
                    value={values.from}
                    inputVariant="outlined"
                    variant="dialog"
                    views={['year', 'month']}
                    openTo="year"
                    labelFunc={(date) => {
                      if (date === null) {
                        return 'Veuillez préciser une date';
                      }
                      const formattedDate = moment(date).format('MMMM YYYY');
                      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
                    }}
                    cancelLabel="Annuler"
                    okLabel="Ok"
                  />
                </Grid>
                <Grid className={classes.item} item sm={6} xs={12}>
                  <DatePicker
                    autoOk={true}
                    fullWidth={true}
                    label="Mois de fin"
                    margin="dense"
                    name="to"
                    error={Boolean(touched.to && errors.to)}
                    helperText={touched.to && errors.to}
                    onChange={(month) => {
                      if (month) {
                        setFieldValue('to', new Date(month.year(), month.month()));
                      }
                      setFieldTouched('to', month?.toDate() !== initialValues.from);
                    }}
                    value={values.to}
                    inputVariant="outlined"
                    variant="dialog"
                    views={['year', 'month']}
                    openTo="year"
                    labelFunc={(date) => {
                      if (date === null) {
                        return 'Veuillez préciser une date';
                      }
                      const formattedDate = moment(date).format('MMMM YYYY');
                      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
                    }}
                    cancelLabel="Annuler"
                    okLabel="Ok"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                disabled={status === 'loading' || !hasEmitted || isSubmitting || !isValid}
                type="submit"
                variant="contained"
              >
                Sauvegarder
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default TimelineLimits;
