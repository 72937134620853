import {FC, Fragment, useMemo, useState} from 'react';

// Components
import AddModal from './add_modal';
import TableContent from './table/content';
import TableShimmer from './table/shimmer';
import ToolbarContent from './toolbar/content';
import ToolbarShimmer from './toolbar/shimmer';

// Externals
import _ from 'lodash';
import moment from 'moment';
import {useFirestore, useFirestoreDocData} from 'reactfire';

// Material
import {AppBar, Box, Button, Container, Dialog, makeStyles, Toolbar, Typography} from '@material-ui/core';

// Material Transition
import Slide, {SlideProps} from '@material-ui/core/Slide';

// Models
import config from '../config';
import {StationDTO} from '../../models/station-v3.dto';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%'
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

interface Props {
  id: string;
  name: string;
  open: boolean;
  onClose: () => void;
}

const MeasurementWindow: FC<Props> = ({id, name, open, onClose}) => {
  const classes = useStyles();

  const stationRef = useFirestore().collection(config.dataCollection).doc(id);

  const {status, data: station, hasEmitted} = useFirestoreDocData<StationDTO>(stationRef, {
    initialData: {
      measuresurments: []
    }
  });

  const data = station.measurements;

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [order, setOrder] = useState<any>({date: 'asc'});
  const [page, setPage] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<{id: string}>();

  const filteredMeasurements = useMemo(() => {
    let measurements = data;

    if (filter) {
      const lowerFilter = filter.toLowerCase();

      return _.filter(data, (measurement) => {
        return (
          measurement.id.indexOf(lowerFilter) >= 0 ||
          moment(measurement.date).utc().format('L').toString().toLowerCase().indexOf(lowerFilter) >= 0 ||
          measurement.value.toString().toLowerCase().indexOf(lowerFilter) >= 0
        );
      });
    }

    return _.orderBy(measurements, Object.keys(order), Object.values(order));
  }, [data, filter, order]);

  return (
    <Fragment>
      <AddModal stationId={id} open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} />
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Slide}
        TransitionProps={{direction: 'up'} as SlideProps}
      >
        <Box className={classes.root}>
          <AppBar position="relative" elevation={0}>
            <Toolbar>
              <Typography variant="h5">{name}</Typography>
              <Box flexGrow={1} />
              <Button color="inherit" onClick={onClose}>
                Fermer
              </Button>
            </Toolbar>
          </AppBar>
          <Container className={classes.container} maxWidth={false}>
            {status === 'loading' || !hasEmitted ? (
              <ToolbarShimmer />
            ) : (
              <ToolbarContent filter={filter} setFilter={setFilter} onAddOpen={() => setIsAddModalOpen(true)} />
            )}
            <Box mt={3}>
              {status === 'loading' || !hasEmitted ? (
                <TableShimmer limit={limit} />
              ) : (
                <TableContent
                  onEditModal={(id: string) => {
                    setSelectedRow({id});
                    setIsEditModalOpen(true);
                  }}
                  limit={limit}
                  setLimit={setLimit}
                  order={order}
                  setOrder={setOrder}
                  page={page}
                  setPage={setPage}
                  selectedMeasurementIds={selectedIds}
                  setSelectedMeasurementIds={setSelectedIds}
                  measurements={filteredMeasurements}
                />
              )}
            </Box>
          </Container>
        </Box>
      </Dialog>
    </Fragment>
  );
};

export default MeasurementWindow;
