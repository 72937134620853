import React, { FC, useEffect, useState } from 'react';

// Externals
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';

// Material
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

// Material Pickers
import { DatePicker } from '@material-ui/pickers';

// Styles
const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: '0 !important'
  },
  title: {
    padding: theme.spacing(2, 3, 1)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

const ShareModal: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const intl = useIntl();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [link, setLink] = useState<string>('');
  const [showTimeline, setShowTimeline] = useState<boolean>(true);
  const [showDefaultMonth, setShowDefaultMonth] = useState<boolean>(false);
  const [defaultMonth, setDefaultMonth] = useState<Date>(new Date());

  const onFocus: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    event.target.select();
    event.target.setSelectionRange(0, 99999); /* For mobile devices */

    document.execCommand('copy');

    const key = enqueueSnackbar(
      intl.formatMessage({
        id: 'Map.ShareButton.ShareModal.Confirm',
        defaultMessage: 'Le code a été copié-collé dans votre presse-papier',
        description: 'Message de confirmation de la fenêtre de partage de la carte'
      }),
      {
        onClick: () => {
          closeSnackbar(key);
        }
      }
    );
  };

  useEffect(() => {
    setLink(
      `<iframe src="${
        window.location.origin
      }/map?showTimeline=${showTimeline}&showDefaultMonth=${showDefaultMonth}&defaultMonth=${new Date(
        Date.UTC(defaultMonth.getFullYear(), defaultMonth.getMonth() + 1, 0)
      ).toISOString()}" title="Les chercheurs d'air - Dioxyde d'azote" width="800" height="560" frameborder="0" ></iframe>`
    );
  }, [showTimeline, showDefaultMonth, defaultMonth]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant="h3">
          <FormattedMessage
            id="Map.ShareButton.ShareModal.Title"
            defaultMessage="Partager la carte"
            description="Titre de la fenêtre de partage de la carte"
          />
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          <FormattedMessage
            id="Map.ShareButton.ShareModal.Description"
            defaultMessage="Copiez-collez le code ci-dessous dans votre site web afin d'intégrer cette carte interactive."
            description="Description de la fenêtre de partage de la carte"
          />
        </DialogContentText>
        <Box mb={1}>
          <TextField
            fullWidth={true}
            label={intl.formatMessage({
              id: 'Map.ShareButton.ShareModal.LinkInput',
              defaultMessage: 'Lien',
              description: 'Intitulé pour le champs "Lien"'
            })}
            margin="dense"
            name="value"
            onChange={(event) => setLink(event.target.value)}
            onFocus={onFocus}
            type="text"
            value={link}
            variant="outlined"
            InputProps={{
              readOnly: true
            }}
          />
        </Box>
        <Box mb={1}>
          <FormControlLabel
            control={
              <Switch
                checked={showTimeline}
                onChange={(event) => setShowTimeline(event.target.checked)}
                name="showTimeline"
                color="primary"
              />
            }
            label={intl.formatMessage({
              id: 'Map.ShareButton.ShareModal.TimelineSwitch',
              defaultMessage: 'Afficher la ligne du temps',
              description: 'Intitulé pour la ligne du temps de la fenêtre de partage de la carte'
            })}
          />
        </Box>
        <Box mb={1}>
          <FormControlLabel
            control={
              <Switch
                checked={showDefaultMonth}
                onChange={(event) => setShowDefaultMonth(event.target.checked)}
                name="showTimeline"
                color="primary"
              />
            }
            label={intl.formatMessage({
              id: 'Map.ShareButton.ShareModal.DefaultMonthSwitch',
              defaultMessage: 'Afficher un mois par défaut',
              description: 'Intitulé pour le mois par défaut de la fenêtre de partage de la carte'
            })}
          />
        </Box>
        <Collapse in={showDefaultMonth}>
          <Box mb={1}>
            <DatePicker
              autoOk={true}
              fullWidth={true}
              label={intl.formatMessage({
                id: 'Map.ShareButton.ShareModal.DefaultMonthInput',
                defaultMessage: 'Mois par défaut',
                description: 'Intitulé pour le champs "Mois par defaut"'
              })}
              margin="dense"
              name="date"
              onChange={(defaultMonth) => {
                if (defaultMonth) setDefaultMonth(defaultMonth.toDate());
              }}
              value={defaultMonth}
              inputVariant="outlined"
              variant="dialog"
              views={['year', 'month']}
              openTo="month"
              labelFunc={(date) => {
                const formattedDate = moment(date).format('MMMM YYYY');
                return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
              }}
              cancelLabel={intl.formatMessage({
                id: 'Common.CancelButton',
                defaultMessage: 'Annuler',
                description: "Intitulé pour le bouton d'annulation"
              })}
              okLabel={intl.formatMessage({
                id: 'Common.OkButton',
                defaultMessage: 'OK',
                description: 'Intitulé pour le bouton de confirmation'
              })}
            />
          </Box>
        </Collapse>
      </DialogContent>
    </Dialog>
  );
};

export default ShareModal;
