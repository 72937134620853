import React, { FC, useEffect, useState } from 'react';

// Externals
import clsx from 'clsx';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useFirestoreDocData, useFirestore } from 'reactfire';
import * as yup from 'yup';

// Material
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

// Models
import { ThresholdsDTO } from 'app/models/threshold.dto';
import LegendPreview from './legend_preview';
import config from '../../data/config';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

interface Props {
  className?: string;
}

interface States {
  firstThreshold: number;
  secondThreshold: number;
  thirdThreshold: number;
  fourthThreshold: number;
}

const Thresholds: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const thresholdsRef = useFirestore().collection(config.settingsCollection).doc('thresholds');

  const { status, data, hasEmitted } = useFirestoreDocData<ThresholdsDTO>(thresholdsRef, {
    initialData: {
      firstThreshold: 0,
      secondThreshold: 0,
      thirdThreshold: 0,
      fourthThreshold: 0
    }
  });

  const [initialValues, setInitialValues] = useState<States>({
    firstThreshold: 0,
    secondThreshold: 0,
    thirdThreshold: 0,
    fourthThreshold: 0
  });

  const onSubmit = async (values: States, actions: FormikHelpers<States>) => {
    try {
      const thresholds: ThresholdsDTO = {
        firstThreshold: values.firstThreshold,
        secondThreshold: values.secondThreshold,
        thirdThreshold: values.thirdThreshold,
        fourthThreshold: values.fourthThreshold
      };

      await thresholdsRef.set(thresholds, { merge: true });

      const key = enqueueSnackbar(`Les seuils ont été sauvegardés avec succès !`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        }
      });
    } catch (error) {
      console.error(error);
      const key = enqueueSnackbar(
        `Les seuils n'ont pas pu être sauvegardés. Si le problème persiste, veuillez contacter votre support technique.`,
        {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(data))
      setInitialValues({
        firstThreshold: data.firstThreshold,
        secondThreshold: data.secondThreshold,
        thirdThreshold: data.thirdThreshold,
        fourthThreshold: data.fourthThreshold
      });
  }, [data]);

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={yup.object().shape({
        firstThreshold: yup
          .number()
          .min(0, `Le premier seuil ne peut pas être inférieur à 0.`)
          .required(`Le premier seuil est nécessaire.`),
        secondThreshold: yup
          .number()
          .min(1, `Le second seuil ne peut pas être inférieur à 1.`)
          .required(`Le second seuil est nécessaire.`),
        thirdThreshold: yup
          .number()
          .min(2, `Le second seuil ne peut pas être inférieur à 2.`)
          .required(`Le second seuil est nécessaire.`),
        fourthThreshold: yup
          .number()
          .min(3, `Le second seuil ne peut pas être inférieur à 3.`)
          .required(`Le second seuil est nécessaire.`)
      })}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        setFieldError,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        values,
        isSubmitting,
        isValid
      }) => (
        <form onSubmit={handleSubmit}>
          <Card className={clsx(classes.root, className)}>
            <CardHeader
              subheader={status === 'loading' || !hasEmitted ? 'Chargement...' : 'Gérer les seuils'}
              title="Seuils"
            />
            <Divider />
            {(status === 'loading' || !hasEmitted || isSubmitting) && <LinearProgress color="secondary" />}
            <CardContent>
              <Grid container spacing={2} wrap="wrap" direction="column">
                <Grid className={classes.item} item sm={6} xs={12}>
                  <TextField
                    disabled={status === 'loading' || !hasEmitted || isSubmitting}
                    error={Boolean(touched.firstThreshold && errors.firstThreshold)}
                    fullWidth={true}
                    helperText={touched.firstThreshold && errors.firstThreshold}
                    label="Premier seuil*"
                    name="firstThreshold"
                    onBlur={() => {
                      setFieldTouched('firstThreshold', true, false);
                    }}
                    onChange={(event) => {
                      if (
                        event.target.value &&
                        +event.target.value > 0 &&
                        +event.target.value >= +values.secondThreshold
                      ) {
                        setFieldValue('firstThreshold', event.target.value, false);
                        setFieldError(
                          'firstThreshold',
                          `Le premier seuil ne peut pas être supérieur ou égale à ${values.secondThreshold}.`
                        );
                      } else {
                        setFieldValue('firstThreshold', event.target.value);
                      }
                    }}
                    type="number"
                    value={values.firstThreshold}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="inherit">µg/m3</Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid className={classes.item} item sm={6} xs={12}>
                  <TextField
                    disabled={status === 'loading' || !hasEmitted || isSubmitting}
                    error={Boolean(touched.secondThreshold && errors.secondThreshold)}
                    fullWidth={true}
                    helperText={touched.secondThreshold && errors.secondThreshold}
                    label="Second seuil*"
                    name="secondThreshold"
                    onBlur={() => {
                      setFieldTouched('secondThreshold', true, false);
                    }}
                    onChange={(event) => {
                      if (
                        event.target.value &&
                        +event.target.value > 0 &&
                        +event.target.value <= +values.firstThreshold
                      ) {
                        setFieldValue('secondThreshold', event.target.value, false);
                        setFieldError(
                          'secondThreshold',
                          `Le second seuil ne peut pas être inférieur ou égale à ${values.firstThreshold}.`
                        );
                      } else {
                        setFieldValue('secondThreshold', event.target.value);
                      }
                    }}
                    type="number"
                    value={values.secondThreshold}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="inherit">µg/m3</Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid className={classes.item} item sm={6} xs={12}>
                  <TextField
                    disabled={status === 'loading' || !hasEmitted || isSubmitting}
                    error={Boolean(touched.thirdThreshold && errors.thirdThreshold)}
                    fullWidth={true}
                    helperText={touched.thirdThreshold && errors.thirdThreshold}
                    label="Troisième seuil*"
                    name="thirdThreshold"
                    onBlur={() => {
                      setFieldTouched('thirdThreshold', true, false);
                    }}
                    onChange={(event) => {
                      if (
                        event.target.value &&
                        +event.target.value > 0 &&
                        +event.target.value <= +values.secondThreshold
                      ) {
                        setFieldValue('thirdThreshold', event.target.value, false);
                        setFieldError(
                          'thirdThreshold',
                          `Le troisième seuil ne peut pas être inférieur ou égale à ${values.secondThreshold}.`
                        );
                      } else {
                        setFieldValue('thirdThreshold', event.target.value);
                      }
                    }}
                    type="number"
                    value={values.thirdThreshold}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="inherit">µg/m3</Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid className={classes.item} item sm={6} xs={12}>
                  <TextField
                    disabled={status === 'loading' || !hasEmitted || isSubmitting}
                    error={Boolean(touched.fourthThreshold && errors.fourthThreshold)}
                    fullWidth={true}
                    helperText={touched.fourthThreshold && errors.fourthThreshold}
                    label="Quatrième seuil*"
                    name="fourthThreshold"
                    onBlur={() => {
                      setFieldTouched('fourthThreshold', true, false);
                    }}
                    onChange={(event) => {
                      if (
                        event.target.value &&
                        +event.target.value > 0 &&
                        +event.target.value <= +values.thirdThreshold
                      ) {
                        setFieldValue('fourthThreshold', event.target.value, false);
                        setFieldError(
                          'fourthThreshold',
                          `Le quatrième seuil ne peut pas être inférieur ou égale à ${values.thirdThreshold}.`
                        );
                      } else {
                        setFieldValue('fourthThreshold', event.target.value);
                      }
                    }}
                    type="number"
                    value={values.fourthThreshold}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="inherit">µg/m3</Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} wrap="wrap">
                <Grid className={classes.item} item xs={12}>
                  <Typography variant="h5">Prévisualisation de la légende</Typography>
                  <LegendPreview
                    firstThreshold={values.firstThreshold}
                    secondThreshold={values.secondThreshold}
                    thirdThreshold={values.thirdThreshold}
                    fourthThreshold={values.fourthThreshold}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                disabled={status === 'loading' || !hasEmitted || isSubmitting || !isValid}
                type="submit"
                variant="contained"
              >
                Sauvegarder
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default Thresholds;
