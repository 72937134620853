// create a settings Context and provider

import React, { createContext, useContext } from 'react';
import firebase from 'firebase';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { ThresholdsDTO } from 'app/models/threshold.dto';
import { TimelineLimitsDto } from 'app/models/timeline_limits.dto';
import moment from 'moment';

interface SettingsProviderProps {
  children: React.ReactNode;
  version: string;
}

interface MapSettings {
  thresholds: {
    firstThreshold: number;
    secondThreshold: number;
    thirdThreshold: number;
    fourthThreshold: number;
  };
  thresholdsFetched: boolean;
  timelineLimits: {
    from: firebase.firestore.Timestamp;
    to: firebase.firestore.Timestamp;
  };
  timelineLimitsFetched: boolean;
  updateTimelineLimits: (limits: TimelineLimitsDto) => Promise<void>;
}

const defaultSettings: MapSettings = {
  thresholds: {
    firstThreshold: 0,
    secondThreshold: 0,
    thirdThreshold: 0,
    fourthThreshold: 0
  },
  thresholdsFetched: false,
  timelineLimits: {
    from: firebase.firestore.Timestamp.fromDate(new Date(2020, 10, 1)),
    to: firebase.firestore.Timestamp.fromDate(new Date())
  },
  timelineLimitsFetched: false,
  updateTimelineLimits: async () => { }
};

export const SettingsContext = createContext<MapSettings>(defaultSettings);

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children, version }: SettingsProviderProps) => {
  const thresholdsRef = useFirestore().collection(version).doc('thresholds');
  const { data: thresholds, hasEmitted: thresholdsHasEmitted } = useFirestoreDocData<ThresholdsDTO>(thresholdsRef, {
    initialData: {
      firstThreshold: 0,
      secondThreshold: 0,
      thirdThreshold: 0,
      fourthThreshold: 0
    }
  });

  const timelineLimitsRef = useFirestore().collection(version).doc('timelineLimits');
  const { data: timelineLimits, hasEmitted: timelineLimitsHasEmitted } = useFirestoreDocData<TimelineLimitsDto>(
    timelineLimitsRef,
    {
      initialData: {
        from: firebase.firestore.Timestamp.fromDate(new Date(2020, 10, 1)),
        to: firebase.firestore.Timestamp.fromDate(moment().subtract(1, 'month').toDate())
      }
    }
  );

  const updateTimelineLimits = (limits: TimelineLimitsDto) => {
    return timelineLimitsRef.set(limits, { merge: true });
  };

  console.log("settings-version", version);
  console.log("data", thresholds, timelineLimits);

  return (
    <SettingsContext.Provider
      value={{
        thresholds,
        thresholdsFetched: thresholdsHasEmitted,
        timelineLimits,
        timelineLimitsFetched: timelineLimitsHasEmitted,
        updateTimelineLimits
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
