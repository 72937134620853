import { FC, useState } from 'react';

// Externals
// import firebase from 'firebase';
import { Formik, FormikHelpers } from 'formik';
// import { useSnackbar } from 'notistack';
// import { useFirestore } from 'reactfire';
import * as yup from 'yup';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { Aigle } from 'aigle';
import { v4 as uuid } from 'uuid';

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useFirestore } from 'reactfire';
import { StationDTO } from 'app/models/station.dto';
import firebase from 'firebase';
import { MeasurementDTO } from 'app/models/measurement.dto';
import moment from 'moment';
import { StationTypes } from 'app/models/station.type';

Aigle.mixin(_, {});

// Styles
const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: '0 !important'
  },
  title: {
    padding: theme.spacing(2, 3, 1)
  },
  linearProgress: {
    marginBottom: theme.spacing(1.5)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

interface States {
  file: File | null;
}

const ImportModal: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const firestore = useFirestore();

  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [initialValues] = useState<States>({
    file: null
  });

  const onReset = (values: States, actions: FormikHelpers<States>) => {
    onClose();
  };

  const onSubmit = async (values: States, actions: FormikHelpers<States>) => {
    const buffer = await values.file?.arrayBuffer();
    const workbook = XLSX.read(buffer, { type: 'buffer' });
    var first_sheet_name = workbook.SheetNames[0];

    /* Get worksheet */
    var worksheet = workbook.Sheets[first_sheet_name];

    const columns = {
      lat: null,
      long: null,
      start_date: null,
      end_date: null,
      mean_conc_corr: null,
      periode: null,
      exposure_time: null,
      person: null,
      site: null,
      category: null
    } as columnsType;

    interface columnsType {
      lat: string | null;
      long: string | null;
      start_date: string | null;
      end_date: string | null;
      mean_conc_corr: string | null;
      periode: string | null;
      exposure_time: string | null;
      person: string | null;
      site: string | null;
      category: string | null;
    }

    type keys = keyof columnsType;

    const columnKeys = Object.keys(columns) as Array<keys>;
    console.log('columnKeys', columnKeys);

    const letters = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    ];

    _.forEach(letters, (letter) => {
      try {
        const title = worksheet[letter + '1'].v;
        if (!title) return;
        const propfound = columnKeys.find((key) => key === title) as keys;
        if (propfound) {
          columns[propfound] = letter;
          console.log('header found', title);
        }
      } catch {}
    });

    console.log('start eval missing values');

    const missingValues = _.filter(columnKeys, (key) => {
      return columns[key] === null;
    });

    if (missingValues.length) {
      alert('missing columns : ' + missingValues);
    }

    let canRead = true;
    let index = 1;
    const measures = [] as Array<columnsType>;
    while (canRead) {
      index++;
      try {
        const measure = {
          lat: worksheet[columns['lat']! + index].w,
          long: worksheet[columns['long']! + index].w,
          start_date: worksheet[columns['start_date']! + index].w,
          end_date: worksheet[columns['end_date']! + index].w,
          mean_conc_corr: worksheet[columns['mean_conc_corr']! + index].w,
          periode: worksheet[columns['periode']! + index].w,
          exposure_time: worksheet[columns['exposure_time']! + index].w,
          person: worksheet[columns['person']! + index].w,
          site: worksheet[columns['site']! + index].w,
          category: worksheet[columns['category']! + index].w
        } as columnsType;

        // exclude invalid data
        const exposure_time = parseInt(measure.exposure_time!);
        if (exposure_time < 240 || exposure_time > 840) {
          continue;
        }

        measures.push(measure);
      } catch {
        canRead = false;
      }
    }

    // console.log('measures', measures.length);

    const stationsRef = firestore.collection('stations');
    const stationsDocs = await stationsRef.get();
    const stations = stationsDocs.docs.map((doc) => doc.data() as StationDTO);

    for (const index in measures) {
      const measure = measures[index];

      let station = _.find(
        stations,
        (station) => station.latitude === measure.lat && station.longitude === measure.long
      );

      if (!station) {
        const isCitizen = measure.person === 'TRUE';

        let stationType = StationTypes.Other;
        switch (measure.category) {
          case 'Citoyen.ne.s':
            stationType = StationTypes.Citizen;
            break;
          case 'Écoles':
            stationType = StationTypes.School;
            break;
          case 'Stations BE':
            stationType = StationTypes.StationBE;
            break;
          default:
            stationType = StationTypes.Other;
        }

        station = {
          id: uuid(),
          coordinates: new firebase.firestore.GeoPoint(parseFloat(measure.lat!), parseFloat(measure.long!)),
          latitude: measure.lat!,
          longitude: measure.long!,
          name: isCitizen ? 'Station n°' + (stations.length + 1) : measure.site!,
          type: stationType
        };

        stations.push(station);
        await stationsRef.doc(station.id).set(station);

        // console.log('station registered');
      } else {
        // console.log('station already exists');
      }

      try {
        const measurementsRef = firestore.collection('stations').doc(station.id).collection('data');

        const periodParts = measure.periode!.split('/');
        const date = moment({
          year: parseInt(periodParts[1]) + (periodParts[1].length === 2 ? 2000 : 0),
          month: parseInt(periodParts[0]) - 1
        }).toDate();
        console.log('periodParts', periodParts);
        console.log('date', date);

        const measurement: MeasurementDTO = {
          id: uuid(),
          date: firebase.firestore.Timestamp.fromDate(date),
          startDate: firebase.firestore.Timestamp.fromDate(moment(measure.start_date!, 'YYYY-MM-DD hh:mm:ss').toDate()),
          endDate: firebase.firestore.Timestamp.fromDate(moment(measure.end_date!, 'YYYY-MM-DD hh:mm:ss').toDate()),
          value: parseFloat(measure.mean_conc_corr!)
        };

        console.log('measurement', measurement);
        await measurementsRef.doc(measurement.id).set(measurement);
      } catch (error) {
        console.log('error while registering measure', error);
      }
    }

    // actions.resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={yup.object().shape({
        file: yup.string().required(`Le fichier est nécessaire.`)
      })}
      onReset={onReset}
      onSubmit={onSubmit}
    >
      {({
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        errors,
        touched,
        values,
        isSubmitting,
        isValid,
        setFieldValue
      }) => (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle className={classes.title} disableTypography>
            <Typography variant="h3">Importation</Typography>
          </DialogTitle>
          <form onReset={handleReset} onSubmit={handleSubmit}>
            <DialogContent onReset={handleReset} className={classes.content}>
              <DialogContentText>
                Veuillez remplir le champ ci-dessous. Le champ marqué par un astérisque est obligatoire.
              </DialogContentText>
              {isSubmitting && <LinearProgress className={classes.linearProgress} color="secondary" />}
              <TextField
                disabled={isSubmitting}
                error={Boolean(touched.file && errors.file)}
                fullWidth={true}
                helperText={touched.file && errors.file}
                label="Fichier*"
                margin="dense"
                name="file"
                onBlur={handleBlur}
                onChange={(event: any) => {
                  setFieldValue('file', (event.target as any).files[0]);
                }}
                type="file"
                variant="outlined"
                inputProps={{
                  accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }}
                InputLabelProps={{ shrink: true }}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="reset">
                Annuler
              </Button>
              <Button color="primary" disabled={isSubmitting || !isValid} type="submit">
                Importer
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default ImportModal;
