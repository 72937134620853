import { FC, useEffect, useState } from 'react';

// Externals

// Material
import { Box, Paper, Typography, makeStyles, Link, useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LegendColors } from 'app/helpers/legend_color';

import LegendModal from './legend_modal';
import { FormattedMessage } from 'react-intl';
import OpenButton from './open_button';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { ThresholdsDTO } from 'app/models/threshold.dto';
import { useSettings } from 'app/helpers/settings-provider';

// Styles
const useStyles = makeStyles((theme) => ({
  box: {
    position: 'absolute',
    left: 10,
    bottom: 20,
    zIndex: 999
  },
  [theme.breakpoints.down('xs')]: {
    box: {
      position: 'absolute',
      left: 10,
      bottom: 20,
      zIndex: 999
    }
  },
  paper: {
    padding: theme.spacing(1),
    minWidth: 'unset',
    minHeight: 'unset',
    zIndex: 999,
    backgroundColor: '#FFFFFFAA',
    display: 'flex',
    flexDirection: 'column'
  },
  legendItem: {
    marginBottom: 8
  },
  colorBox: {
    width: 15,
    height: 15,
    borderRadius: 2,
    display: 'inline-block',
    marginRight: 8
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: '#fd5c63'
    }
  },
  closeIcon: {
    padding: 0,
    alignSelf: 'center'
  },
  [theme.breakpoints.up('sm')]: {
    closeIcon: {
      display: 'none'
    }
  }
}));

const Legend: FC = () => {
  const classes = useStyles();

  const { thresholds } = useSettings();

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = useState(upSm);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setOpen(upSm);
  }, [upSm]);

  if (!open) {
    return <OpenButton onClick={() => setOpen(true)} />;
  }

  return (
    <Box className={classes.box}>
      <Paper className={classes.paper}>
        <Typography variant="body1" className={classes.legendItem}>
          <i className={classes.colorBox} style={{ backgroundColor: LegendColors.Level5 }}></i>
          {`>${thresholds.fourthThreshold}µg/m3`}
        </Typography>

        <Typography variant="body1" className={classes.legendItem}>
          <i className={classes.colorBox} style={{ backgroundColor: LegendColors.Level4 }}></i>
          <FormattedMessage
            tagName="span"
            id="Map.Legend.Range"
            defaultMessage="{from} à {to}"
            values={{
              from: thresholds.thirdThreshold,
              to: thresholds.fourthThreshold
            }}
          />
        </Typography>

        <Typography variant="body1" className={classes.legendItem}>
          <i className={classes.colorBox} style={{ backgroundColor: LegendColors.Level3 }}></i>
          <FormattedMessage
            tagName="span"
            id="Map.Legend.Range"
            defaultMessage="{from} à {to}"
            values={{
              from: thresholds.secondThreshold,
              to: thresholds.thirdThreshold
            }}
          />
        </Typography>

        <Typography variant="body1" className={classes.legendItem}>
          <i className={classes.colorBox} style={{ backgroundColor: LegendColors.Level2 }}></i>
          <FormattedMessage
            tagName="span"
            id="Map.Legend.Range"
            defaultMessage="{from} à {to}"
            values={{
              from: thresholds.firstThreshold,
              to: thresholds.secondThreshold
            }}
          />
        </Typography>

        <Typography variant="body1" className={classes.legendItem}>
          <i className={classes.colorBox} style={{ backgroundColor: LegendColors.Level1 }}></i>
          {`<${thresholds.firstThreshold}µg/m3`}
        </Typography>

        <Typography variant="body2" className={classes.legendItem}>
          <Link component="a" className={classes.link} onClick={() => setShowModal(true)}>
            <FormattedMessage
              tagName="span"
              id="Map.Legend.KnowMore"
              defaultMessage="En savoir plus"
              description="Lien pour afficher la modale d'explication de la légende"
            />
          </Link>
        </Typography>
        <IconButton component="span" className={classes.closeIcon} onClick={() => setOpen(false)}>
          <CloseIcon titleAccess="Close" />
        </IconButton>
      </Paper>
      <LegendModal open={showModal} onClose={() => setShowModal(false)} />
    </Box>
  );
};

export default Legend;
