// Models
import { ThresholdsDTO } from 'app/models/threshold.dto';
import { useSettings } from '../settings-provider';

export const useLegendColor = (): [(value: number) => string, ThresholdsDTO] => {
  const { thresholds } = useSettings();

  const getLegend = (value: number) => {
    if (value <= thresholds.firstThreshold) {
      return thresholdColor(1);
    } else if (value <= thresholds.secondThreshold) {
      return thresholdColor(2);
    } else if (value <= thresholds.thirdThreshold) {
      return thresholdColor(3);
    } else if (value <= thresholds.fourthThreshold) {
      return thresholdColor(4);
    } else {
      return thresholdColor(5);
    }
  };

  return [getLegend, thresholds];
};

type ThresholdIndex = 1 | 2 | 3 | 4 | 5;

export const thresholdColor = (index: ThresholdIndex) => {
  switch (index) {
    case 1:
      return LegendColors.Level1;
    case 2:
      return LegendColors.Level2;
    case 3:
      return LegendColors.Level3;
    case 4:
      return LegendColors.Level4;
    case 5:
      return LegendColors.Level5;
  }
};

export const LegendColors = {
  Level1: '#7ABD7E',
  Level2: '#FFB54C',
  Level3: '#FF4646',
  Level4: '#9100A1',
  Level5: '#000000'
};
