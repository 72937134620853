import { FC, useMemo, useState } from 'react';

// Common
import Page from 'app/common/page';

// Components
import AddModal from './add_modal';
import DeleteModal from './delete_modal';
import EditModal from './edit_modal';
import ExportModal from './export_modal';
import ImportModal from './import_modal';
import MeasurementWindow from './measurement_window';
import TableContent from './table/content';
import TableShimmer from './table/shimmer';
import ToolbarContent from './toolbar/content';
import ToolbarShimmer from './toolbar/shimmer';

// Externals
import _ from 'lodash';
import { useFirestoreCollectionData, useFirestore } from 'reactfire';

// Material
import { Box, Container, makeStyles } from '@material-ui/core';

// Models
import { StationDTO } from 'app/models/station.dto';
import { getStationTypeLabel } from 'app/models/station.type';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Data: FC = () => {
  const classes = useStyles();

  const stationsRef = useFirestore().collection('stations');

  const { status, data, hasEmitted } = useFirestoreCollectionData<StationDTO>(stationsRef, {
    initialData: []
  });

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isMeasurementWindowOpen, setIsMeasurementWindowOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [order, setOrder] = useState<any>({ name: 'asc' });
  const [page, setPage] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<{ id: string; name: string }>();

  const filteredStations = useMemo(() => {
    let stations = data;

    if (filter) {
      const lowerFilter = filter.toLowerCase();

      stations = _.filter(data, (station) => {
        return (
          station.id.indexOf(lowerFilter) >= 0 ||
          station.coordinates.latitude.toString().toLowerCase().indexOf(lowerFilter) >= 0 ||
          station.coordinates.longitude.toString().toLowerCase().indexOf(lowerFilter) >= 0 ||
          station.name.toLowerCase().indexOf(lowerFilter) >= 0 ||
          getStationTypeLabel(station.type).toLowerCase().indexOf(lowerFilter) >= 0
        );
      });
    }

    return _.orderBy(stations, Object.keys(order), Object.values(order));
  }, [data, filter, order]);

  return (
    <Page className={classes.root} title="Gestion des données">
      <Container maxWidth={false}>
        <AddModal open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} />
        <DeleteModal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
        {selectedRow && (
          <EditModal id={selectedRow.id} open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} />
        )}
        <ExportModal open={isExportModalOpen} onClose={() => setIsExportModalOpen(false)} data={data} />
        <ImportModal open={isImportModalOpen} onClose={() => setIsImportModalOpen(false)} />
        {selectedRow && (
          <MeasurementWindow
            id={selectedRow.id}
            name={selectedRow.name}
            open={isMeasurementWindowOpen}
            onClose={() => setIsMeasurementWindowOpen(false)}
          />
        )}
        {status === 'loading' || !hasEmitted ? (
          <ToolbarShimmer />
        ) : (
          <ToolbarContent
            filter={filter}
            setFilter={setFilter}
            onAddOpen={() => setIsAddModalOpen(true)}
            onDeleteOpen={selectedIds.length > 0 ? () => setIsDeleteModalOpen(true) : undefined}
            onImportOpen={() => setIsImportModalOpen(true)}
            onExportOpen={() => setIsExportModalOpen(true)}
          />
        )}
        <Box mt={3}>
          {status === 'loading' || !hasEmitted ? (
            <TableShimmer limit={limit} />
          ) : (
            <TableContent
              onEditModal={(id: string, name: string) => {
                setSelectedRow({ id, name });
                setIsEditModalOpen(true);
              }}
              onMeasurementWindow={(id: string, name: string) => {
                setSelectedRow({ id, name });
                setIsMeasurementWindowOpen(true);
              }}
              limit={limit}
              setLimit={setLimit}
              order={order}
              setOrder={setOrder}
              page={page}
              setPage={setPage}
              selectedStationIds={selectedIds}
              setSelectedStationIds={setSelectedIds}
              stations={filteredStations}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default Data;
