import React, { FC, useEffect, useMemo, useState } from 'react';

// Components
import ShareButton from './share_button';
import Timeline from './timeline';
import MonthPicker from './month_picker';

// Externals
import moment from 'moment';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Map as MapView, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// Models
import LangButton from './lang_button';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { StationDTO } from '../../../models/station.dto';
import withLocale from '../../../../i18n/with_locale';
import MarkerList from './marker_list';
import Legend from './legend';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useSettings } from '../../../helpers/settings-provider';

const MapV2: FC<{ locale?: string }> = ({ locale }) => {
  moment.locale(locale);

  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

  const stationsRef = useFirestore().collection('stations2023');

  const { data: stationData } = useFirestoreCollectionData<StationDTO>(stationsRef, {
    initialData: []
  });

  const { timelineLimits } = useSettings();

  const theme = useTheme();
  const downXs = useMediaQuery(theme.breakpoints.down('xs'));

  const setDisplayDate = (value: Date) => {
    setSelectedDate(value);
    setSelectedEndDate(moment(value).add(1, 'month').toDate());
  };

  const showTimeline = useMemo(
    () =>
      queryString.parse(location.search).showTimeline
        ? (queryString.parse(location.search).showTimeline as string) === 'true'
        : undefined,
    [location]
  );
  const showDefaultMonth = useMemo(
    () =>
      queryString.parse(location.search).showDefaultMonth
        ? (queryString.parse(location.search).showDefaultMonth as string) === 'true'
        : undefined,
    [location]
  );
  const defaultMonth = useMemo(
    () =>
      queryString.parse(location.search).defaultMonth
        ? new Date(queryString.parse(location.search).defaultMonth as string)
        : undefined,
    [location]
  );

  useEffect(() => {
    if (
      ((showTimeline === true && showDefaultMonth === true) || (showTimeline === false && showDefaultMonth === true)) &&
      defaultMonth !== undefined
    ) {
      setSelectedDate(defaultMonth);
      setSelectedEndDate(moment(defaultMonth).add(1, 'month').toDate());
    } else {
      const date = moment(timelineLimits.to.toDate().setDate(1)).toDate();
      setSelectedDate(moment(date.setHours(0, 0, 0, 0)).toDate());
      setSelectedEndDate(moment(date.setHours(0, 0, 0, 0)).add(1, 'month').toDate());
    }
  }, [showTimeline, showDefaultMonth, defaultMonth, timelineLimits]);

  console.log("stations data", stationData);

  return (
    <div>
      <Legend />
      <LangButton />
      <ShareButton />
      {showTimeline &&
        (downXs ? (
          <MonthPicker selectedDate={selectedDate} setSelectedDate={setDisplayDate} />
        ) : (
          <Timeline selectedDate={selectedDate} setSelectedDate={setDisplayDate} />
        ))}
      <MapView
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        initialViewState={{
          longitude: 4.3545358,
          latitude: 50.8268688,
          zoom: 13
        }}
        style={{ height: '100vh', width: '100vw' }}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      >
        {stationData.map((station, id) => {
          return <MarkerList key={id} station={station} selectedDate={selectedDate} endDate={selectedEndDate} />;
        })}
        <NavigationControl position={'top-left'} />
      </MapView>
    </div>
  );
};

export default withLocale(MapV2);
