import { StationDTO } from '../../../../models/station.dto';
import { MeasurementDTO } from '../../../../models/measurement.dto';
import React, { useMemo } from 'react';
import _ from 'lodash';
import CustomMarker from './marker';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

interface Props {
  station: StationDTO;
  selectedDate: Date;
  endDate: Date;
}

const MarkerList = ({ station, selectedDate, endDate }: Props) => {
  const measurementsRef = useFirestore().collection('stations2023').doc(station.id).collection('data');

  const { data } = useFirestoreCollectionData<MeasurementDTO>(measurementsRef, {
    initialData: []
  });

  const measurements = useMemo(() => {
    return _.orderBy(
      _.filter(data, (measurement) => {
        return measurement.date.toDate() >= selectedDate && measurement.date.toDate() < endDate;
      }),
      'startDate',
      'asc'
    );
  }, [data, selectedDate, endDate]);

  if (measurements.length) {
    const periodMeasurement =
      measurements.length === 1
        ? measurements[0]
        : ({
          startDate: _.first(measurements)?.startDate,
          endDate: _.last(measurements)?.endDate,
          date: measurements[0].date,
          value: _.reduce(measurements, (avg, m) => avg + m.value / measurements.length, 0)
        } as MeasurementDTO);

    return (
      <>
        {measurements.map((marker) => {
          return <CustomMarker key={marker.id} station={station} measurement={periodMeasurement} />;
        })}
      </>
    );
  }
  return null;
};

export default MarkerList;
