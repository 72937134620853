import { FC, useEffect, useMemo, useState } from 'react';

// Externals
import moment from 'moment';
import * as _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import isTouchDevice from 'is-touch-device';

// Material
import { Box, makeStyles, Paper, Tooltip, useMediaQuery, useTheme, withStyles } from '@material-ui/core';

// Material Slider
import Slider from '@material-ui/core/Slider';
import withLocale from 'i18n/with_locale';
import { useSettings } from '../../../../helpers/settings-provider';

// Styles
const useStyles = makeStyles((theme) => ({
  box: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: theme.spacing(2),
    zIndex: 999,
    margin: theme.spacing(0, 1)
  },
  paper: {
    background: theme.palette.primary.main + 'DD',
    width: '100%',
    height: 'auto'
  },
  scroll: {
    padding: theme.spacing(1, 5, 1, 5)
  },
  arrow: {
    color: '#FFFFFF'
  },
  tooltip: {
    boxShadow: theme.shadows[1],
    background: '#FFFFFF',
    color: theme.palette.getContrastText('#FFFFFF')
  },
  [theme.breakpoints.down('xs')]: {
    box: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: theme.spacing(0),
      zIndex: 999,
      margin: theme.spacing(0, 0)
    },
    scroll: {
      padding: theme.spacing(1, 5, 4, 5)
    }
  }
}));

const CustomSlider = withStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    minWidth: 500
  },
  rail: {
    opacity: 0.5,
    backgroundColor: '#FFFFFF'
  },
  mark: {
    opacity: 0.5,
    height: 8,
    width: 1,
    backgroundColor: '#FFFFFF'
  },
  markActive: {
    opacity: 1
  },
  markLabel: {
    color: '#FFFFFF'
  }
}))(Slider);

interface Props {
  selectedDate: Date;
  setSelectedDate: (value: Date) => void;
  locale?: string;
}

const Timeline: FC<Props> = ({ selectedDate, setSelectedDate, locale }) => {
  selectedDate = moment(selectedDate).startOf('month').toDate();

  const { timelineLimits, timelineLimitsFetched } = useSettings();

  const [startMonth, diffMonths] = useMemo((): [start: Date, diff: number] => {
    const startMonth: Date = timelineLimits.from.toDate();
    const endMonth: Date = timelineLimits.to.toDate();
    const diffMonths = Math.ceil(moment(endMonth).diff(startMonth, 'month')) + 1;
    return [startMonth, diffMonths];
  }, [timelineLimits]);

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const [value, setValue] = useState<number>(Math.ceil(moment(selectedDate).diff(startMonth, 'month') + 1));

  const marks = useMemo(() => {
    return _.map(new Array(diffMonths), (_, index) => {
      return {
        value: index + 1,
        label: moment(startMonth)
          .add(index, 'month')
          .locale(locale!)
          .format(downSm ? 'MM/YY' : 'MMM YYYY')
      };
    });
  }, [downSm, locale, startMonth, diffMonths]);

  useEffect(() => {
    setValue(Math.ceil(moment(selectedDate).diff(startMonth, 'month') + 1));
  }, [selectedDate, startMonth]);

  if (!timelineLimitsFetched) {
    return null;
  }

  return (
    <Box className={classes.box} display="flex" alignContent="center" justifyContent="center">
      <Paper className={classes.paper}>
        <PerfectScrollbar
          className={classes.scroll}
          options={{ suppressScrollY: true, useBothWheelAxes: !isTouchDevice() }}
        >
          <CustomSlider
            onChange={(event, value) => {
              event.stopPropagation();
              setValue(value as number);
              setSelectedDate(
                moment(startMonth)
                  .add((value as number) - 1, 'month')
                  .toDate()
              );
            }}
            marks={marks}
            min={1}
            draggable={!isTouchDevice()}
            max={diffMonths}
            step={1}
            value={value}
            ValueLabelComponent={({ children, open, value }) => (
              <Tooltip
                arrow={true}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                open={open}
                enterTouchDelay={0}
                placement="top"
                title={value}
              >
                {children}
              </Tooltip>
            )}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) =>
              moment(startMonth)
                .add(value - 1, 'month')
                .locale(locale!)
                .format('MMMM YYYY')
            }
          />
        </PerfectScrollbar>
      </Paper>
    </Box>
  );
};

export default withLocale(Timeline);
