import { FC } from 'react';

// Externals
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Helpers
import { useLegendColor } from 'app/helpers/legend_color';

// Material
import {
  Box,
  Card,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';

// Models
import { MeasurementDTO } from 'app/models/measurement.dto';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    justifyContent: 'center',
    width: 150
  },
  row: {
    cursor: 'pointer'
  }
}));

interface Props {
  className?: string;
  onEditModal: (id: string) => void;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  order: any;
  setOrder: React.Dispatch<React.SetStateAction<any>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  selectedMeasurementIds: string[];
  setSelectedMeasurementIds: React.Dispatch<React.SetStateAction<string[]>>;
  measurements: MeasurementDTO[];
}

const Content: FC<Props> = ({
  className,
  onEditModal,
  limit,
  setLimit,
  order,
  setOrder,
  page,
  setPage,
  selectedMeasurementIds,
  setSelectedMeasurementIds,
  measurements
}) => {
  const classes = useStyles();

  const [legendColor] = useLegendColor();

  const handleEditClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
    event.stopPropagation();

    onEditModal(id);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLimit(+event.target.value);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleSelectClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    let newSelectedMeasurementIds: string[] = [];

    if (event.target.checked) {
      newSelectedMeasurementIds = _.map(measurements, (measurement) => measurement.id);
    }

    setSelectedMeasurementIds(newSelectedMeasurementIds);
  };

  const handleSelectOne = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    event.stopPropagation();

    const selectedIndex = _.indexOf(selectedMeasurementIds, id);
    let newSelectedMeasurementIds: string[] = [];

    if (selectedIndex === -1) {
      newSelectedMeasurementIds = _.concat(newSelectedMeasurementIds, selectedMeasurementIds, id);
    } else if (selectedIndex === 0) {
      newSelectedMeasurementIds = _.concat(newSelectedMeasurementIds, _.slice(selectedMeasurementIds, 1));
    } else if (selectedIndex === selectedMeasurementIds.length - 1) {
      newSelectedMeasurementIds = _.concat(newSelectedMeasurementIds, _.slice(selectedMeasurementIds, 0, -1));
    } else if (selectedIndex > 0) {
      newSelectedMeasurementIds = _.concat(
        _.slice(selectedMeasurementIds, 0, selectedIndex),
        _.slice(selectedMeasurementIds, selectedIndex + 1)
      );
    }

    setSelectedMeasurementIds(newSelectedMeasurementIds);
  };

  const handleSort = (key: string) => () => {
    if (isSorted(key)) {
      if (order[key] === 'asc') setOrder({ [key]: 'desc' });
      else setOrder({});
    } else {
      setOrder({ [key]: 'asc' });
    }
  };

  const isSorted = (key: string) => order.hasOwnProperty(key);

  const getSortDirection = (key: string) => order[key];

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedMeasurementIds.length === measurements.length}
                    color="primary"
                    indeterminate={
                      selectedMeasurementIds.length > 0 && selectedMeasurementIds.length < measurements.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Identifiant</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSorted('value')}
                    direction={getSortDirection('value')}
                    onClick={handleSort('value')}
                  >
                    Valeur
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSorted('date')}
                    direction={getSortDirection('date')}
                    onClick={handleSort('date')}
                  >
                    Période
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSorted('startDate')}
                    direction={getSortDirection('startDate')}
                    onClick={handleSort('startDate')}
                  >
                    Début
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSorted('endDate')}
                    direction={getSortDirection('endDate')}
                    onClick={handleSort('endDate')}
                  >
                    Fin
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(_.drop(measurements, page * limit).slice(0, limit), (measurement) => (
                <TableRow
                  className={classes.row}
                  hover
                  key={measurement.id}
                  onClick={(event) => handleEditClick(event, measurement.id)}
                  selected={_.indexOf(selectedMeasurementIds, measurement.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={_.indexOf(selectedMeasurementIds, measurement.id) !== -1}
                      color="primary"
                      onChange={(event) => handleSelectOne(event, measurement.id)}
                      onClick={handleSelectClick}
                    />
                  </TableCell>
                  <TableCell>{measurement.id}</TableCell>
                  <TableCell>
                    <Chip
                      className={classes.chip}
                      label={`${measurement.value.toFixed(2)} µg/m3`}
                      variant="default"
                      style={{
                        background: legendColor(measurement.value),
                        color: '#FFFFFF'
                      }}
                    />
                  </TableCell>
                  <TableCell>{moment(measurement.date.toDate()).format('MMM YYYY')}</TableCell>
                  <TableCell>{moment(measurement.startDate.toDate()).format('L')}</TableCell>
                  <TableCell>{moment(measurement.endDate.toDate()).format('L')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={measurements.length}
        labelDisplayedRows={({ from, to, count }) => {
          return from + '-' + to + ' sur ' + count;
        }}
        labelRowsPerPage="Élements par page:"
        onPageChange={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default Content;
