import React, { useState } from 'react';

export interface WithLocaleProps {
  locale: string;
  setLocale: (locale: string) => void;
}

interface LocalizedAppProps {}

const LocalizedApp: React.FunctionComponent<LocalizedAppProps> = (props): any & LocalizedAppProps => {
  const [locale, setLocale] = useState('fr');

  return (props.children as any)({ locale, setLocale });
};

export default LocalizedApp;
