import { LegendColors } from 'app/helpers/legend_color';
import { ThresholdsDTO } from 'app/models/threshold.dto';
import type { LayerProps } from 'react-map-gl';

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'stations',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#51bbd6',
    'circle-radius': ['step', ['get', 'point_count'], 15, 20, 25, 50, 35]
  }
};

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'stations',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  }
};

export const unclusteredPointLayer = (thresholds: ThresholdsDTO): LayerProps => {
  return {
    interactive: true,
    id: 'unclustered-point',
    type: 'circle',
    source: 'stations',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': ['step', ['get', 'no2value'], LegendColors.Level1,
        thresholds.firstThreshold, LegendColors.Level2,
        thresholds.secondThreshold, LegendColors.Level3,
        thresholds.thirdThreshold, LegendColors.Level4,
        thresholds.fourthThreshold, LegendColors.Level5],
      'circle-radius': 10,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff'
    }
  }
};