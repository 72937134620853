import { FC, StrictMode, useEffect } from 'react';

// Externals
import { useUser } from 'reactfire';
import { useRoutes } from 'react-router-dom';

// Routes
import routes from 'app/routes';

declare global {
  interface Window {
    onReactReady: () => void;
  }
}

const App: FC = () => {
  const { status, data, hasEmitted } = useUser();

  const routing = useRoutes(routes(Boolean(data)));

  useEffect(() => {
    // *** BUG *** hasEmitted is always false
    // Waiting for the next version
    if (
      status !== 'loading'
      // && hasEmitted
    ) {
      window.onReactReady();
    }
  }, [status, hasEmitted]);

  return <StrictMode>{routing}</StrictMode>;
};

export default App;
