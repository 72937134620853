import { FC, useState } from 'react';

// Externals
// import firebase from 'firebase';
import { Formik, FormikHelpers } from 'formik';
//import _, { forEach } from 'lodash';
// import { useSnackbar } from 'notistack';
// import { useFirestore } from 'reactfire';
import * as yup from 'yup';
import * as XLSX from 'xlsx';
import moment from 'moment';

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { StationDTO } from 'app/models/station.dto';
import { useFirestore } from 'reactfire';
import { MeasurementDTO } from 'app/models/measurement.dto';

// Styles
const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: '0 !important'
  },
  title: {
    padding: theme.spacing(2, 3, 1)
  },
  linearProgress: {
    marginBottom: theme.spacing(1.5)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
  data: StationDTO[];
}

interface States {
  filename: string;
}

interface StationWithMeasures extends StationDTO {
  measures: MeasurementDTO[];
}

const Export: FC<Props> = ({ open, onClose, data }) => {
  const classes = useStyles();

  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const firestore = useFirestore();

  const [initialValues] = useState<States>({
    filename: ''
  });

  const onReset = (values: States, actions: FormikHelpers<States>) => {
    onClose();
  };

  const onSubmit = async (values: States, actions: FormikHelpers<States>) => {
    const stations = [] as StationWithMeasures[];
    for (var index in data) {
      const station = data[index];

      const measures = await firestore.collection('stations').doc(station.id).collection('data').get();

      stations.push({
        ...station,
        measures: measures.docs.map((doc) => doc.data() as MeasurementDTO)
      });
    }

    //Code de Gui pour l'export XLSX :
    //J'ai aussi import XLSX line 10 et moment line 11

    //fonction pour retourner la date comme dans le google sheet
    const formatDate = (date: Date) => {
      //2021-03-26 09:56:00
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    };

    //Nom du docucment
    let downloadfilename = `${values.filename}.xlsx`;
    //Creation d'un doc xlsx
    let workbook = XLSX.utils.book_new();

    //hearder du doc
    const sheet = XLSX.utils.json_to_sheet([{}], {
      header: [
        'site',
        'category',
        'lat',
        'long',
        'start_date',
        'end_date',
        'mean_conc_corr',
        'person',
        'exposure_time',
        'periode'
      ]
    });
    //l'origine en A2 car le header est en A1
    let origin: any = 'A2';

    stations.forEach((station) => {
      let lineContent: string[] = [];
      //get les info et on et mets dans un tableau
      lineContent[0] = station.name;
      lineContent[1] = station.type;
      lineContent[2] = station.coordinates.latitude.toString();
      lineContent[3] = station.coordinates.longitude.toString();
      lineContent[7] = station.type.toString() === 'Citizen' ? 'VRAI' : 'FAUX';

      station.measures.forEach((data) => {
        //on format la date pour la stocker
        let startmeasureDate = formatDate(data.startDate.toDate());
        let endmeasureDate = formatDate(data.endDate.toDate());
        lineContent[4] = `${startmeasureDate}`;
        lineContent[5] = `${endmeasureDate}`;
        lineContent[6] = `${data.value}`;

        //exposure_time impossible de trouver la valeur
        lineContent[8] = ' ';
        //Calcul de différence d'heure avec moment.js
        //let startdate = moment(data.startDate.toDate());
        //let enddate = moment(data.endDate.toDate());
        //lineContent[8] = enddate.diff(startdate, 'hours', true).toString();

        lineContent[9] = moment(data.date.toDate()).format('MM/YY');

        //on ajouter a notre sheet le tableau lineContent, 1 element = 1 element d'une colone
        XLSX.utils.sheet_add_json(sheet, [lineContent], {
          //skipHeader pour pas voir des chiffre a chaque colones
          skipHeader: true,
          origin: origin
        });
        // origin -1 = ligne suivante
        origin = -1;
      });
    });
    //on ajoute notre sheet a notre fichier
    XLSX.utils.book_append_sheet(workbook, sheet);
    //on ecris le fichier sous le nom entrer par l'utilisateur
    XLSX.writeFile(workbook, downloadfilename);

    actions.resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={yup.object().shape({
        filename: yup.string().max(255).required(`Le nom du fichier est nécessaire.`)
      })}
      onReset={onReset}
      onSubmit={onSubmit}
    >
      {({ handleBlur, handleChange, handleReset, handleSubmit, errors, touched, values, isSubmitting, isValid }) => (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle className={classes.title} disableTypography>
            <Typography variant="h3">Exportation</Typography>
          </DialogTitle>
          <form onReset={handleReset} onSubmit={handleSubmit}>
            <DialogContent className={classes.content}>
              <DialogContentText>
                Veuillez remplir le champ ci-dessous. Le champ marqué par un astérisque est obligatoire.
              </DialogContentText>
              {isSubmitting && <LinearProgress className={classes.linearProgress} color="secondary" />}
              <TextField
                disabled={isSubmitting}
                error={Boolean(touched.filename && errors.filename)}
                fullWidth={true}
                helperText={touched.filename && errors.filename}
                label="Nom du fichier*"
                margin="dense"
                name="filename"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.filename}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="reset">
                Annuler
              </Button>
              <Button color="primary" disabled={isSubmitting || !isValid} type="submit">
                Exporter
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default Export;
