import { FC } from 'react';

// Externals
import clsx from 'clsx';

// Material
import { Box, Button, Paper, TextField, InputAdornment, makeStyles } from '@material-ui/core';

// Material Icons
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      alignItems: 'unset',
      flexDirection: 'column'
    }
  },
  button: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(1)
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '0 !important',
      width: '100%'
    }
  },
  input: {
    width: '285px',

    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  }
}));

interface Props {
  className?: string;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  onAddOpen: () => void;
  onDeleteOpen?: () => void;
  onImportOpen: () => void;
  onExportOpen: () => void;
}

const Content: FC<Props> = ({ className, filter, setFilter, onAddOpen, onDeleteOpen, onImportOpen, onExportOpen }) => {
  const classes = useStyles();

  const handleFilterChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <Paper className={clsx(classes.root, className)}>
      <Box className={classes.input}>
        <TextField
          fullWidth={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color={filter.length > 0 ? 'inherit' : 'disabled'} fontSize="small" />
              </InputAdornment>
            )
          }}
          onChange={handleFilterChange}
          placeholder="Chercher une station de mesure"
          size="small"
          type="text"
          value={filter}
          variant="outlined"
        />
      </Box>
      <Box>
        <Button className={classes.button} onClick={onImportOpen} startIcon={<PublishIcon />}>
          Importer
        </Button>
        <Button className={classes.button} onClick={onExportOpen} startIcon={<GetAppIcon />}>
          Exporter
        </Button>
        {onDeleteOpen && (
          <Button
            className={classes.button}
            color="primary"
            onClick={onDeleteOpen}
            startIcon={<DeleteIcon />}
            variant="contained"
          >
            Supprimer
          </Button>
        )}
        <Button
          className={classes.button}
          color="primary"
          onClick={onAddOpen}
          startIcon={<AddIcon />}
          variant="contained"
        >
          Ajouter
        </Button>
      </Box>
    </Paper>
  );
};

export default Content;
