import { FC, Fragment, useState } from 'react';

// Components
import ShareModal from './share_modal';

// Externals
import { useIntl } from 'react-intl';
import isTouchDevice from 'is-touch-device';

// Material
import { Box, Button, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

// Material Icons
import ShareIcon from '@material-ui/icons/Share';

// Styles
const useStyles = makeStyles((theme) => ({
  box: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 999
  },
  button: {
    width: 40,
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main + 'DD',
    '&:hover': {
      backgroundColor: theme.palette.primary.main + 'EE',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main + 'DD'
      }
    },
    minWidth: 'unset',
    minHeight: 'unset'
  }
}));

const ShareButton: FC = () => {
  const classes = useStyles();

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('xs'));

  const intl = useIntl();

  const [open, setOpen] = useState(false);

  if (downSm && isTouchDevice()) return null;

  return (
    <Fragment>
      <ShareModal open={open} onClose={() => setOpen(false)} />
      <Box className={classes.box}>
        <Button
          className={classes.button}
          onClick={() => setOpen(true)}
          title={intl.formatMessage({
            id: 'Common.ShareButton',
            defaultMessage: 'Partager',
            description: 'Intitulé pour le bouton de partage'
          })}
        >
          <ShareIcon />
        </Button>
      </Box>
    </Fragment>
  );
};

export default ShareButton;
