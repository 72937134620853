import React, { FC } from 'react';

// Externals
import { useIntl } from 'react-intl';

// Material
import {
  Box,
  Paper,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Models
import { StationsVisibility, StationType, StationTypes } from 'app/models/station.type';
import OpenButton from './open_button';

// Styles
const useStyles = makeStyles((theme) => ({
  box: {
    position: 'absolute',
    right: 10,
    bottom: 100,
    zIndex: 999
  },
  [theme.breakpoints.down('xs')]: {
    box: {
      position: 'absolute',
      right: 10,
      bottom: 20,
      zIndex: 999
    }
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1),
    minWidth: 'unset',
    minHeight: 'unset',
    zIndex: 999,
    backgroundColor: '#FFFFFFAA',
    display: 'flex',
    flexDirection: 'column'
  },
  formItem: {
    marginBottom: 8,
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0
    }
  },
  checkbox: {
    padding: 0,
    paddingRight: theme.spacing(1)
  },
  closeIcon: {
    padding: 0,
    alignSelf: 'center'
  },
  [theme.breakpoints.up('sm')]: {
    closeIcon: {
      display: 'none'
    }
  }
}));

interface FilterProps {
  showData: StationsVisibility;
  setShowData: (type: StationType, value: boolean) => void;
}

const Filter: FC<FilterProps> = (props: FilterProps) => {
  const classes = useStyles();
  const intl = useIntl();

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = React.useState(upSm);

  React.useEffect(() => {
    setOpen(upSm);
  }, [upSm]);

  if (!open) {
    return <OpenButton onClick={() => setOpen(true)} />;
  }

  return (
    <Box className={classes.box}>
      <Paper className={classes.paper}>
        <Typography variant="body1" className={classes.formItem}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                checked={props.showData[StationTypes.Citizen]}
                onChange={(e, checked) => {
                  props.setShowData(StationTypes.Citizen, checked);
                  if (!checked && !props.showData[StationTypes.Citizen]) {
                    props.setShowData(StationTypes.Citizen, true);
                  }
                }}
                name="show_citizens"
              />
            }
            label={intl.formatMessage({ id: 'Map.Filter.ShowCitizens', defaultMessage: 'Citoyen.ne.s' })}
          />
        </Typography>

        <Typography variant="body1" className={classes.formItem}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                checked={props.showData[StationTypes.School]}
                onChange={(e, checked) => {
                  props.setShowData(StationTypes.School, checked);
                  if (!checked && !props.showData[StationTypes.School]) {
                    props.setShowData(StationTypes.School, true);
                  }
                }}
                name="show_schools"
              />
            }
            label={intl.formatMessage({ id: 'Map.Filter.ShowSchools', defaultMessage: 'Écoles' })}
          />
        </Typography>

        <Typography variant="body1" className={classes.formItem}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                checked={props.showData[StationTypes.StationBE]}
                onChange={(e, checked) => {
                  props.setShowData(StationTypes.StationBE, checked);
                  if (!checked && !props.showData[StationTypes.StationBE]) {
                    props.setShowData(StationTypes.StationBE, true);
                  }
                }}
                name="show_stationsBE"
              />
            }
            label={intl.formatMessage({ id: 'Map.Filter.ShowStationBE', defaultMessage: 'Stations BE' })}
          />
        </Typography>

        <Typography variant="body1" className={classes.formItem}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                checked={props.showData[StationTypes.Other]}
                onChange={(e, checked) => {
                  props.setShowData(StationTypes.Other, checked);
                  if (!checked && !props.showData[StationTypes.Other]) {
                    props.setShowData(StationTypes.Other, true);
                  }
                }}
                name="show_others"
              />
            }
            label={intl.formatMessage({ id: 'Map.Filter.ShowOther', defaultMessage: 'Autres' })}
          />
        </Typography>

        <IconButton component="span" className={classes.closeIcon} onClick={() => setOpen(false)}>
          <CloseIcon titleAccess="Close" />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default Filter;
