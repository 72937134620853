import { FC, useState } from 'react';

// Externals
import firebase from 'firebase';
import { Formik, FormikHelpers, FormikState } from 'formik';
import { useSnackbar } from 'notistack';
import { useFirestore } from 'reactfire';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

// Models
import { StationDTO } from 'app/models/station.dto';
import { StationType, getStationTypeLabel, StationTypes } from 'app/models/station.type';

// Styles
const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: '0 !important'
  },
  title: {
    padding: theme.spacing(2, 3, 1)
  },
  linearProgress: {
    marginBottom: theme.spacing(1.5)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

interface States {
  name: string;
  latitude: number;
  longitude: number;
  type: string;
}

const AddModal: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const stationsRef = useFirestore().collection('stations2023');

  const [initialValues] = useState<States>({
    name: '',
    latitude: 0,
    longitude: 0,
    type: ''
  });

  const onReset = (resetForm: (nextState?: Partial<FormikState<States>> | undefined) => void) => {
    resetForm();

    onClose();
  };

  const onSubmit = async (values: States, actions: FormikHelpers<States>) => {
    try {
      const station: StationDTO = {
        id: uuid(),
        coordinates: new firebase.firestore.GeoPoint(values.latitude, values.longitude),
        latitude: values.latitude.toString(),
        longitude: values.longitude.toString(),
        name: values.name,
        type: values.type as StationType
      };

      await stationsRef.doc(station.id).set(station);

      const key = enqueueSnackbar(`La station de mesure a été ajoutée avec succès !`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        }
      });

      onReset(actions.resetForm);
    } catch (error) {
      console.error(error);

      const key = enqueueSnackbar(
        `La station de mesure n'a pu être ajoutée. Si le problème persiste, veuillez contacter votre support technique.`,
        {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          }
        }
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={yup.object().shape({
        name: yup.string().required(`Le nom est nécessaire.`),
        latitude: yup
          .number()
          .min(-90, `La latitude ne peut pas être inférieur à 90°.`)
          .min(-90, `La latitude ne peut pas être supérieur à 90°.`)
          .required(`La latitude est nécessaire.`),
        longitude: yup
          .number()
          .min(-180, `La longitude ne peut pas être inférieur à 180°.`)
          .max(180, `La longitude ne peut pas être supérieur à 180°.`)
          .required(`La longitude est nécessaire.`),
        type: yup.string().required(`Le type est nécessaire.`)
      })}
      onSubmit={onSubmit}
    >
      {({ handleBlur, handleChange, handleSubmit, resetForm, errors, touched, values, isSubmitting, isValid }) => (
        <Dialog open={open} onClose={() => onReset(resetForm)}>
          <DialogTitle className={classes.title} disableTypography>
            <Typography variant="h3">Ajout</Typography>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className={classes.content}>
              <DialogContentText>
                Veuillez remplir les champs ci-dessous. Les champs marqués par un astérisque sont obligatoires.
              </DialogContentText>
              {isSubmitting && <LinearProgress className={classes.linearProgress} color="secondary" />}
              <TextField
                disabled={isSubmitting}
                error={Boolean(touched.name && errors.name)}
                fullWidth={true}
                helperText={touched.name && errors.name}
                label="Nom*"
                margin="dense"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                disabled={isSubmitting}
                error={Boolean(touched.latitude && errors.latitude)}
                fullWidth={true}
                helperText={touched.latitude && errors.latitude}
                label="Latitude*"
                margin="dense"
                name="latitude"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.latitude}
                variant="outlined"
              />
              <TextField
                disabled={isSubmitting}
                error={Boolean(touched.longitude && errors.longitude)}
                fullWidth={true}
                helperText={touched.longitude && errors.longitude}
                label="Longitude*"
                margin="dense"
                name="longitude"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.longitude}
                variant="outlined"
              />
              <TextField
                disabled={isSubmitting}
                error={Boolean(touched.type && errors.type)}
                fullWidth={true}
                helperText={touched.type && errors.type}
                label="Type*"
                margin="dense"
                name="type"
                onBlur={handleBlur}
                onChange={handleChange}
                select
                value={values.type}
                variant="outlined"
              >
                <MenuItem value={StationTypes.Citizen}>{getStationTypeLabel(StationTypes.Citizen)}</MenuItem>
                <MenuItem value={StationTypes.School}>{getStationTypeLabel(StationTypes.School)}</MenuItem>
                <MenuItem value={StationTypes.StationBE}>{getStationTypeLabel(StationTypes.StationBE)}</MenuItem>
                <MenuItem value={StationTypes.Other}>{getStationTypeLabel(StationTypes.Other)}</MenuItem>
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => onReset(resetForm)} type="button">
                Annuler
              </Button>
              <Button color="primary" disabled={isSubmitting || !isValid} type="submit" variant="contained">
                Ajouter
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddModal;
