import { FC } from 'react';

// Externals
import clsx from 'clsx';

// Material
import { Box, Paper, makeStyles } from '@material-ui/core';

// Material Lab
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      alignItems: 'unset',
      flexDirection: 'column'
    }
  },
  button: {
    display: 'inline-flex',
    borderRadius: theme.shape.borderRadius,
    height: '40px',
    width: '117px',

    '&:not(:first-child)': {
      marginLeft: theme.spacing(1)
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '0 !important',
      width: '100%'
    }
  },
  input: {
    borderRadius: theme.shape.borderRadius,
    height: '40px',
    width: '350px',

    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  }
}));

interface Props {
  className?: string;
}

const Shimmer: FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.root, className)}>
      <Skeleton className={classes.input} variant="rect" />
      <Box>
        <Skeleton className={classes.button} variant="rect" />
      </Box>
    </Paper>
  );
};

export default Shimmer;
