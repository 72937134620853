import { FC } from 'react';

// Externals
import { useIntl } from 'react-intl';

// Material
import { Box, ButtonGroup, IconButton, makeStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';

// Context
import { LocaleContext } from 'index';

// Styles
const useStyles = makeStyles((theme) => ({
  box: {
    position: 'absolute',
    left: 10,
    bottom: 20,
    display: 'block',
    zIndex: 999
  },
  button: {
    width: 40,
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main + 'DD',
    '&:hover': {
      backgroundColor: theme.palette.primary.main + 'EE',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main + 'DD'
      }
    },
    minWidth: 'unset',
    minHeight: 'unset'
  }
}));

interface Props {
  onClick: () => void;
}

const OpenButton: FC<Props> = (props) => {
  const classes = useStyles();

  const intl = useIntl();

  return (
    <LocaleContext.Consumer>
      {({ locale, setLocale }) => {
        return (
          <Box className={classes.box}>
            <ButtonGroup orientation="vertical" variant="contained">
              <IconButton
                className={classes.button}
                onClick={props.onClick}
                title={intl.formatMessage({
                  id: 'Map.LegendOpen',
                  defaultMessage: 'Afficher la légende',
                  description: "Intitulé pour le bouton d'ouverture de la légende"
                })}
              >
                <HelpIcon />
              </IconButton>
            </ButtonGroup>
          </Box>
        );
      }}
    </LocaleContext.Consumer>
  );
};

export default OpenButton;
