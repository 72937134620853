import * as React from 'react';

// Externals
import { makeStyles } from '@material-ui/core';

//
import { thresholdColor } from 'app/helpers/legend_color';

interface LegendPreviewProps {
  firstThreshold: number;
  secondThreshold: number;
  thirdThreshold: number;
  fourthThreshold: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: 200,
    height: 5
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const LegendPreview = (props: LegendPreviewProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ flexGrow: props.firstThreshold, backgroundColor: thresholdColor(1) }}></div>
      <div style={{ flexGrow: props.secondThreshold - props.firstThreshold, backgroundColor: thresholdColor(2) }}></div>
      <div style={{ flexGrow: props.thirdThreshold - props.secondThreshold, backgroundColor: thresholdColor(3) }}></div>
      <div style={{ flexGrow: props.fourthThreshold - props.thirdThreshold, backgroundColor: thresholdColor(4) }}></div>
      <div style={{ flexGrow: props.firstThreshold, backgroundColor: thresholdColor(5) }}></div>
    </div>
  );
};

export default LegendPreview;
