export type StationType = 'Citizen' | 'School' | 'Hospital' | 'StationBE' | 'Other';

export const StationTypes = {
  Citizen: 'Citizen' as StationType,
  School: 'School' as StationType,
  StationBE: 'StationBE' as StationType,
  Hospital: 'Hospital' as StationType,
  Other: 'Other' as StationType
};

const data = {
  [StationTypes.School]: true,
  [StationTypes.Hospital]: true,
};
export type StationsVisibility = typeof data;

export const getStationTypeLabel = (value: StationType) => {
  switch (value) {
    case StationTypes.Hospital:
      return 'Hôpital';
    case StationTypes.School:
      return 'École';
    case StationTypes.StationBE:
      return 'Station BE';
    case StationTypes.Citizen:
      return 'Citoyen.ne';
    case StationTypes.Other:
      return 'Autre';
    default:
      return 'Inconnu';
  }
};
