import {FC} from 'react';

// Externals
import {FormattedMessage} from 'react-intl';

// Material
import {Dialog, DialogContent, DialogTitle, Typography, makeStyles, IconButton, useMediaQuery} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {LegendColors} from 'app/helpers/legend_color';
import theme from 'app/theme';

// Styles
const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: '0 !important',
    textAlign: 'justify',
    '& p': {
      margin: theme.spacing(0, 0, 1)
    }
  },
  title: {
    padding: theme.spacing(2, 3, 1)
  },
  subtitle: {
    padding: theme.spacing(2, 0, 1)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

const LegendModal: FC<Props> = ({open, onClose}) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" scroll="paper" fullScreen={fullScreen}>
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant="h3">
          <FormattedMessage
            id="Map.V3.Legend.LegendModal.Title"
            defaultMessage="Légende expliquée"
            description="Titre de la fenêtre d'explications de la légende"
          />
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body1">
          <FormattedMessage
            tagName="span"
            id="Map.V3.Legend.LegendModal.Section_1_1"
            defaultMessage="Chaque point représente une école. Les couleurs correspondent à la qualité de l'air définie en fonction de la concentration moyenne annuelle en NO2. Le code couleur que nous avons utilisé se base sur la <link>recommandation de l’OMS</link>. Pour le NO2, la ligne directrice se situe à 10µg/m³ en moyenne annuelle, limite au-dessus de laquelle il existe “des risques importants pour la santé publique”."
            description="Description de la fenêtre de légende de la carte"
            values={{
              link: (text: string) => (
                <a
                  href="https://iris.who.int/bitstream/handle/10665/345334/9789240034433-eng.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              )
            }}
          />
        </Typography>

        <Typography variant="h4" className={classes.subtitle}>
          <FormattedMessage
            tagName="span"
            id="Map.V3.Legend.LegendModal.Section_3_title"
            defaultMessage="Index"
            description="Titre de la section 'Index'"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            tagName="span"
            id="Map.V3.Legend.LegendModal.Section_3_1_v2"
            defaultMessage="Concentration moyenne annuelle en µg de NO2 par m³<br></br>
              <green>Vert</green> : Sous le seuil annuel de l'OMS (<10µg/m3)<br></br>
              <orange>Jaune</orange> : Dépasse d'une fois le seuil annuel de l'OMS (10 à 20µg/m3)<br></br>
              <red>Rouge</red> : Dépasse de 2 fois le seuil annuel de l'OMS (20 à 30µg/m3)<br></br>
              <violet>Violet</violet> : Dépasse de 3 fois le seuil annuel de l'OMS (30 à 40µg/m3)<br></br>
              <black>Noir</black> : Dépasse de 4 fois et plus le seuil annuel de l'OMS (>40µg/m3)<br></br>"
            description="Description de la fenêtre de légende de la carte"
            values={{
              black: (text: string) => <span style={{color: LegendColors.Level5}}>{text}</span>,
              violet: (text: string) => <span style={{color: LegendColors.Level4}}>{text}</span>,
              red: (text: string) => <span style={{color: LegendColors.Level3}}>{text}</span>,
              orange: (text: string) => <span style={{color: LegendColors.Level2}}>{text}</span>,
              green: (text: string) => <span style={{color: LegendColors.Level1}}>{text}</span>,
              br: (text: string) => <br />
            }}
          />
        </Typography>

        <Typography variant="h4" className={classes.subtitle}>
          <FormattedMessage
            tagName="span"
            id="Map.Legend.LegendModal.Section_4_title"
            defaultMessage="Pourquoi ces chiffres ?"
            description="Titre de la section 'Pourquoi ces chiffres ?'"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            tagName="span"
            id="Map.Legend.LegendModal.Section_4_1_v2"
            defaultMessage="Les nouvelles recommandations mondiales de l’OMS, présentées en septembre 2021, indiquent que la concentration annuelle en NO2 ne doit pas dépasser 10µg/m3. Ces recommandations ont pour objectif de mieux protéger la santé des citoyen.nes, les dépassements de ces seuils représentant des risques importants pour la santé."
            description="Description de la fenêtre de légende de la carte"
          />
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default LegendModal;
