import { FC, useState } from 'react';

// Externals
import { Formik, FormikHelpers, FormikState } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useFirestore } from 'reactfire';
import * as yup from 'yup';

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: '0 !important'
  },
  title: {
    padding: theme.spacing(2, 3, 1)
  },
  linearProgress: {
    marginBottom: theme.spacing(1.5)
  }
}));

interface Props {
  stationId: string;
  open: boolean;
  onClose: () => void;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
}

interface States {}

const AddModal: FC<Props> = ({ stationId, open, onClose, selectedIds, setSelectedIds }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const measurementsRef = useFirestore().collection('stations2023').doc(stationId).collection('data');

  const [initialValues] = useState<States>({});

  const onReset = (resetForm: (nextState?: Partial<FormikState<States>> | undefined) => void) => {
    resetForm();

    onClose();
  };

  const onSubmit = async (values: States, actions: FormikHelpers<States>) => {
    try {
      _.forEach(selectedIds, async (id) => {
        await measurementsRef.doc(id).delete();
      });

      const key = enqueueSnackbar(
        selectedIds.length > 1
          ? `Les mesures en dioxyde d'azote ont été supprimées avec succès !`
          : `La mesure en dioxyde d'azote a été supprimée avec succès !`,
        {
          variant: 'success',
          onClick: () => {
            closeSnackbar(key);
          }
        }
      );

      setSelectedIds([]);

      onReset(actions.resetForm);
    } catch (error) {
      console.error(error);

      const key = enqueueSnackbar(
        selectedIds.length > 1
          ? `Les mesures en dioxyde d'azote n'ont pas pu être supprimées. Si le problème persiste, veuillez contacter votre support technique.`
          : `La mesure en dioxyde d'azote n'a pu être supprimée. Si le problème persiste, veuillez contacter votre support technique.`,
        {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          }
        }
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={true}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={yup.object().shape({})}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, resetForm, isSubmitting, isValid }) => (
        <Dialog open={open} onClose={() => onReset(resetForm)}>
          <DialogTitle className={classes.title} disableTypography>
            <Typography variant="h3">Suppression</Typography>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className={classes.content}>
              <DialogContentText>
                Êtes-vous sûr de vouloir supprimer {selectedIds.length}
                {selectedIds.length > 1 ? ` mesures en dioxyde d'azote ?` : ` mesure en dioxyde d'azote ?`}
              </DialogContentText>
              {isSubmitting && <LinearProgress className={classes.linearProgress} color="secondary" />}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => onReset(resetForm)} type="reset">
                Annuler
              </Button>
              <Button color="primary" disabled={isSubmitting || !isValid} type="submit" variant="contained">
                Supprimer
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddModal;
