import { FC } from 'react';

// Externals
import clsx from 'clsx';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';

// Material Lab
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {},
  radius: {
    borderRadius: theme.shape.borderRadius
  }
}));

interface Props {
  className?: string;
  limit: number;
}

const Shimmer: FC<Props> = ({ className, limit }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Box display="flex" alignItems="center">
                    <Box p={1.375}>
                      <Skeleton className={classes.radius} height={20} width={20} variant="rect" />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>Identifiant</TableCell>
                <TableCell>Valeur</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(new Array(limit), (_value, index) => (
                <TableRow hover key={index}>
                  <TableCell padding="checkbox">
                    <Box display="flex" alignItems="center">
                      <Box p={1.375}>
                        <Skeleton className={classes.radius} height={20} width={20} variant="rect" />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box display="flex" justifyContent="flex-end">
        <Box display="flex" alignItems="center" ml={2}>
          <Skeleton width={140} variant="text" />
        </Box>
        <Box display="flex" alignItems="center" ml={2}>
          <Skeleton width={80} variant="text" />
        </Box>
        <Box display="flex" alignItems="center" ml={2}>
          <Box p={1.5}>
            <Skeleton height={24} width={24} variant="circle" />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box p={1.5}>
            <Skeleton height={24} width={24} variant="circle" />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default Shimmer;
