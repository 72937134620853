import { FC } from 'react';

// Externals
import clsx from 'clsx';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material
import {
  Box,
  Card,
  Checkbox,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core';

// Material Icons
import DataUsageIcon from '@material-ui/icons/DataUsage';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ReportIcon from '@material-ui/icons/Report';
import SchoolIcon from '@material-ui/icons/School';
import StationBeIcon from '@material-ui/icons/AccountBalance';
import OtherIcon from '@material-ui/icons/BlurOn';

// Models
import { StationDTO } from 'app/models/station.dto';
import { getStationTypeLabel, StationType, StationTypes } from 'app/models/station.type';

const icons = {
  [StationTypes.Citizen]: EmojiPeopleIcon,
  [StationTypes.School]: SchoolIcon,
  [StationTypes.StationBE]: StationBeIcon,
  [StationTypes.Other]: OtherIcon
};

const getIcon = (type: StationType | string) => {
  let IconComponent = ReportIcon;

  if (icons[type]) {
    IconComponent = icons[type];
  }

  return <IconComponent />;
};

// Styles
const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 112
  },
  iconButton: {
    width: 42,
    height: 42
  },
  row: {
    cursor: 'pointer'
  },
  tooltip: {
    background: theme.palette.primary.main
  }
}));

interface Props {
  className?: string;
  onEditModal: (id: string, name: string) => void;
  onMeasurementWindow: (id: string, name: string) => void;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  order: any;
  setOrder: React.Dispatch<React.SetStateAction<any>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  selectedStationIds: string[];
  setSelectedStationIds: React.Dispatch<React.SetStateAction<string[]>>;
  stations: StationDTO[];
}

const Content: FC<Props> = ({
  className,
  onEditModal,
  onMeasurementWindow,
  limit,
  setLimit,
  order,
  setOrder,
  page,
  setPage,
  selectedStationIds,
  setSelectedStationIds,
  stations
}) => {
  const classes = useStyles();

  const handleEditClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string, name: string) => {
    event.stopPropagation();

    onEditModal(id, name);
  };

  const handleMeasurementClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, name: string) => {
    event.stopPropagation();

    onMeasurementWindow(id, name);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLimit(+event.target.value);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleSelectClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelectedStationIds: string[] = [];

    if (event.target.checked) {
      newSelectedStationIds = _.map(stations, (station) => station.id);
    }

    setSelectedStationIds(newSelectedStationIds);
  };

  const handleSelectOne = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = _.indexOf(selectedStationIds, id);
    let newSelectedStationIds: string[] = [];

    if (selectedIndex === -1) {
      newSelectedStationIds = _.concat(newSelectedStationIds, selectedStationIds, id);
    } else if (selectedIndex === 0) {
      newSelectedStationIds = _.concat(newSelectedStationIds, _.slice(selectedStationIds, 1));
    } else if (selectedIndex === selectedStationIds.length - 1) {
      newSelectedStationIds = _.concat(newSelectedStationIds, _.slice(selectedStationIds, 0, -1));
    } else if (selectedIndex > 0) {
      newSelectedStationIds = _.concat(
        _.slice(selectedStationIds, 0, selectedIndex),
        _.slice(selectedStationIds, selectedIndex + 1)
      );
    }

    setSelectedStationIds(newSelectedStationIds);
  };

  const handleSort = (key: string) => () => {
    if (isSorted(key)) {
      if (order[key] === 'asc') setOrder({ [key]: 'desc' });
      else setOrder({});
    } else {
      setOrder({ [key]: 'asc' });
    }
  };

  const isSorted = (key: string) => order.hasOwnProperty(key);

  const getSortDirection = (key: string) => order[key];

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedStationIds.length === stations.length}
                    color="primary"
                    indeterminate={selectedStationIds.length > 0 && selectedStationIds.length < stations.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSorted('name')}
                    direction={getSortDirection('name')}
                    onClick={handleSort('name')}
                  >
                    Nom
                  </TableSortLabel>
                </TableCell>
                <TableCell>Identifiant</TableCell>
                <TableCell>Coordonnées</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isSorted('type')}
                    direction={getSortDirection('type')}
                    onClick={handleSort('type')}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center" width={75}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(_.drop(stations, page * limit).slice(0, limit), (station) => (
                <TableRow
                  className={classes.row}
                  hover
                  key={station.id}
                  onClick={(event) => handleEditClick(event, station.id, station.name)}
                  selected={_.indexOf(selectedStationIds, station.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={_.indexOf(selectedStationIds, station.id) !== -1}
                      color="primary"
                      onChange={(event) => handleSelectOne(event, station.id)}
                      onClick={handleSelectClick}
                    />
                  </TableCell>
                  <TableCell>{station.name}</TableCell>
                  <TableCell>{station.id}</TableCell>
                  <TableCell>{`${station.coordinates.latitude}, ${station.coordinates.longitude}`}</TableCell>
                  <TableCell>
                    <Chip
                      className={classes.chip}
                      color="primary"
                      icon={getIcon(station.type)}
                      label={getStationTypeLabel(station.type)}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="center" width={75}>
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip
                      }}
                      placement="left"
                      title="Gérer les mesures en dioxyde d'azote"
                    >
                      <IconButton
                        className={classes.iconButton}
                        color="primary"
                        onClick={(event) => handleMeasurementClick(event, station.id, station.name)}
                      >
                        <DataUsageIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={stations.length}
        labelDisplayedRows={({ from, to, count }) => {
          return from + '-' + to + ' sur ' + count;
        }}
        labelRowsPerPage="Élements par page:"
        onPageChange={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default Content;
