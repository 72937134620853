// Externals
import { Navigate, Outlet } from 'react-router-dom';

// Layout
import DashboardLayout from 'app/layout/dashboard_layout';
import EmptyLayout from 'app/layout/empty_layout';
import MainLayout from 'app/layout/main_layout';

// Views
import Dashboard from 'app/views/v1/dashboard';
import Data from 'app/views/v1/data';
import Login from 'app/views/login';
import Map from 'app/views/v1/map/index';
import NotFound from 'app/views/not_found';
import Settings from 'app/views/v1/settings';
import MapV2 from 'app/views/v2/map';
import SettingsV2 from './views/v2/settings';
import DataV2 from './views/v2/data';
import { SettingsProvider } from './helpers/settings-provider';
import DashboardV2 from './views/v2/dashboard';

// V3
import DashboardV3 from 'app/views/v3/dashboard';
import DataV3 from 'app/views/v3/data';
import SettingsV3 from 'app/views/v3/settings';
import MapV3 from 'app/views/v3/map';

const anonymous = (isLoggedIn: boolean, component: any) => (!isLoggedIn ? component : <Navigate to="/app/dashboard" />);

const authorize = (isLoggedIn: boolean, component: any) => (isLoggedIn ? component : <Navigate to="/login" />);

const routes = (isLoggedIn: boolean) => [
  {
    path: '/app',
    element: <DashboardLayout />,
    children: [
      {
        path: 'dashboard',
        element: authorize(
          isLoggedIn,
          <SettingsProvider version={'settings'}>
            <Dashboard />
          </SettingsProvider>
        )
      },
      {
        path: 'data',
        element: authorize(
          isLoggedIn,
          <SettingsProvider version={'settings'}>
            <Data />
          </SettingsProvider>
        )
      },
      {
        path: 'settings',
        element: authorize(
          isLoggedIn,
          <SettingsProvider version={'settings'}>
            <Settings />
          </SettingsProvider>
        )
      },
      {
        path: 'map2023',
        element: authorize(
          isLoggedIn,
          <SettingsProvider version={'settings2023'}>
            <DashboardV2 />
          </SettingsProvider>
        )
      },
      {
        path: 'data_v2',
        element: authorize(
          isLoggedIn,
          <SettingsProvider version={'settings2023'}>
            <DataV2 />
          </SettingsProvider>
        )
      },
      {
        path: 'settings_v2',
        element: authorize(
          isLoggedIn,
          <SettingsProvider version={'settings2023'}>
            <SettingsV2 />
          </SettingsProvider>
        )
      },
      {
        path: 'v3',
        element: <SettingsProvider version='settings-v3'>
          <Outlet />
        </SettingsProvider>,
        children: [
          {
            path: 'map',
            element: authorize(
              isLoggedIn,
              <DashboardV3 />
            )
          },
          {
            path: 'data',
            element: authorize(
              isLoggedIn,
              <DataV3 />
            )
          },
          {
            path: 'settings',
            element: authorize(
              isLoggedIn,
              <SettingsV3 />
            )
          },
        ]
      },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/map',
    element: <EmptyLayout />,
    children: [
      {
        path: '/',
        element: (
          <SettingsProvider version={'settings'}>
            <Map />
          </SettingsProvider>
        )
      },
      {
        path: '*',
        element: (
          <SettingsProvider version={'settings'}>
            <Map />
          </SettingsProvider>
        )
      }
    ]
  },
  {
    path: '/map_v2',
    element: <EmptyLayout />,
    children: [
      {
        path: '/',
        element: (
          <SettingsProvider version={'settings2023'}>
            <MapV2 />
          </SettingsProvider>
        )
      },
      {
        path: '*',
        element: (
          <SettingsProvider version={'settings2023'}>
            <MapV2 />
          </SettingsProvider>
        )
      }
    ]
  },
  {
    path: '/v3',
    element:
      <SettingsProvider version={'settings-v3'}>
        <EmptyLayout />
      </SettingsProvider>,
    children: [
      {
        path: '/',
        element: (
          <MapV3 />
        )
      },
      {
        path: '*',
        element: (
          <MapV3 />
        )
      }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: anonymous(isLoggedIn, <Login />) },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
