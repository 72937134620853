import { FC, useState } from 'react';

// Externals
import firebase from 'firebase';
import { Formik, FormikHelpers, FormikState } from 'formik';
import { useSnackbar } from 'notistack';
import { useFirestore } from 'reactfire';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

// Material Pickers
import { DatePicker } from '@material-ui/pickers';

// Models
import { MeasurementDTO } from 'app/models/measurement.dto';
import config from '../../config';

// Styles
const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: '0 !important'
  },
  title: {
    padding: theme.spacing(2, 3, 1)
  },
  linearProgress: {
    marginBottom: theme.spacing(1.5)
  }
}));

interface Props {
  stationId: string;
  open: boolean;
  onClose: () => void;
}

interface States {
  date: Date;
  value: number;
  startDate: Date;
  endDate: Date;
}

const AddModal: FC<Props> = ({ stationId, open, onClose }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const measurementsRef = useFirestore().collection(config.dataCollection).doc(stationId).collection('data');

  const [initialValues] = useState<States>({
    date: new Date(),
    value: 0,
    startDate: new Date(),
    endDate: new Date()
  });

  const onReset = (resetForm: (nextState?: Partial<FormikState<States>> | undefined) => void) => {
    resetForm();

    onClose();
  };

  const onSubmit = async (values: States, actions: FormikHelpers<States>) => {
    try {
      const measurement: MeasurementDTO = {
        id: uuid(),
        date: firebase.firestore.Timestamp.fromDate(values.date),
        startDate: firebase.firestore.Timestamp.fromDate(values.startDate),
        endDate: firebase.firestore.Timestamp.fromDate(values.endDate),
        value: values.value
      };

      await measurementsRef.doc(measurement.id).set(measurement);

      const key = enqueueSnackbar(`La mesure en dioxyde d'azote a été ajoutée avec succès !`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        }
      });

      onReset(actions.resetForm);
    } catch (error) {
      console.error(error);

      const key = enqueueSnackbar(
        `La mesure en dioxyde d'azote n'a pu être ajoutée. Si le problème persiste, veuillez contacter votre support technique.`,
        {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          }
        }
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={yup.object().shape({
        date: yup.date().required(`Le date est nécessaire.`),
        value: yup
          .number()
          .min(0, `La valeur ne peut pas être inférieur à 0 µg/m3.`)
          .required(`La valeur est nécessaire.`)
      })}
      onSubmit={onSubmit}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
        errors,
        touched,
        values,
        isSubmitting,
        isValid
      }) => (
        <Dialog open={open} onClose={() => onReset(resetForm)} maxWidth="xs">
          <DialogTitle className={classes.title} disableTypography>
            <Typography variant="h3">Ajout</Typography>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className={classes.content}>
              <DialogContentText>
                Veuillez remplir les champs ci-dessous. Les champs marqués par un astérisque sont obligatoires.
              </DialogContentText>
              {isSubmitting && <LinearProgress className={classes.linearProgress} color="secondary" />}
              <DatePicker
                autoOk={true}
                disabled={isSubmitting}
                disableToolbar={true}
                error={Boolean(touched.date && errors.date)}
                fullWidth={true}
                helperText={touched.date && errors.date}
                label="Date*"
                margin="dense"
                name="date"
                onBlur={handleBlur}
                onChange={(date) => {
                  if (date) setFieldValue('date', date.toDate());
                }}
                value={values.date}
                inputVariant="outlined"
                format="DD/MM/YYYY"
                variant="inline"
              />
              <TextField
                disabled={isSubmitting}
                error={Boolean(touched.value && errors.value)}
                fullWidth={true}
                helperText={touched.value && errors.value}
                label="Valeur*"
                margin="dense"
                name="value"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.value}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="inherit">µg/m3</Typography>
                    </InputAdornment>
                  )
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => onReset(resetForm)} type="reset">
                Annuler
              </Button>
              <Button color="primary" disabled={isSubmitting || !isValid} type="submit" variant="contained">
                Ajouter
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddModal;
