import { FC, useState } from 'react';

// Externals
import moment from 'moment';

// Material
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';

// Material Slider
import withLocale from 'i18n/with_locale';
import { DatePicker } from '@material-ui/pickers';
import { useSettings } from 'app/helpers/settings-provider';

// Styles
const useStyles = makeStyles((theme) => ({
  box: {
    position: 'absolute',
    left: 100,
    right: 100,
    bottom: 20,
    zIndex: 999,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  paper: {
    background: theme.palette.primary.main + 'DD',
    padding: theme.spacing(1, 3),
    color: 'white',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  arrow: {
    color: '#FFFFFF'
  },
  tooltip: {
    boxShadow: theme.shadows[1],
    background: '#FFFFFF',
    color: theme.palette.getContrastText('#FFFFFF')
  }
}));

interface Props {
  selectedDate: Date;
  setSelectedDate: (value: Date) => void;
  locale?: string;
}

const MonthPicker: FC<Props> = ({ setSelectedDate, selectedDate, locale }) => {
  const { timelineLimits, timelineLimitsFetched } = useSettings();

  const [show, setShow] = useState(false);

  const classes = useStyles();

  if (!timelineLimitsFetched) {
    return null;
  }
  moment.locale(locale);

  if (show) {
    return (
      <DatePicker
        autoOk={true}
        fullWidth={true}
        label="Mois de fin"
        onChange={(month) => {
          if (month) {
            setSelectedDate(month.toDate());
            setShow(false);
          }
        }}
        style={{ display: 'none' }}
        onClose={() => setShow(false)}
        value={selectedDate}
        inputVariant="standard"
        variant="dialog"
        open={true}
        views={['year', 'month']}
        openTo="year"
        inputProps={{ hidden: true }}
        minDate={timelineLimits.from.toDate()}
        maxDate={timelineLimits.to.toDate()}
        cancelLabel="Annuler"
        okLabel="Ok"
      />
    );
  }

  return (
    <Box className={classes.box} display="flex" alignContent="center" justifyContent="center">
      <Paper className={classes.paper} onClick={() => setShow(true)}>
        <Typography>{moment(selectedDate).locale(locale!).format('MMMM YYYY')}</Typography>
      </Paper>
    </Box>
  );
};

export default withLocale(MonthPicker);
