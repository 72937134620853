import React, { FC, useEffect, useMemo, useState } from 'react';

// Components
import Legend from './legend';
import ShareButton from './share_button';
import Timeline from './timeline';
import MonthPicker from './month_picker';

// Externals
import moment from 'moment';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Map as MapView, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// Models
import LangButton from './lang_button';
import Filter from './filter';
import { StationType, StationTypes } from 'app/models/station.type';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { StationDTO } from '../../../models/station.dto';
import withLocale from '../../../../i18n/with_locale';
import MarkerList from './marker_list';
import { useSettings } from '../../../helpers/settings-provider';

const Map: FC<{ locale?: string }> = ({ locale }) => {
  const [showData, setShowData] = React.useState({
    [StationTypes.School]: true,
    [StationTypes.Citizen]: true,
    [StationTypes.StationBE]: true,
    [StationTypes.Other]: true
  });

  const setShowDataType = (type: StationType, value: boolean) => {
    setShowData({ ...showData, [type]: value });
  };

  moment.locale(locale);

  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const stationsRef = useFirestore().collection('stations');
  const { timelineLimits } = useSettings();

  const { data: stationData } = useFirestoreCollectionData<StationDTO>(stationsRef, {
    initialData: []
  });

  const filteredData = useMemo(() => stationData?.filter((station) => showData[station.type]), [stationData, showData]);
  const theme = useTheme();
  const downXs = useMediaQuery(theme.breakpoints.down('xs'));

  const setDisplayDate = (value: Date) => {
    setSelectedDate(value);
    setSelectedEndDate(moment(value).add(1, 'month').toDate());
  };

  const showTimeline = useMemo(
    () =>
      queryString.parse(location.search).showTimeline
        ? (queryString.parse(location.search).showTimeline as string) === 'true'
        : undefined,
    [location]
  );

  const showDefaultMonth = useMemo(
    () =>
      queryString.parse(location.search).showDefaultMonth
        ? (queryString.parse(location.search).showDefaultMonth as string) === 'true'
        : undefined,
    [location]
  );
  const defaultMonth = useMemo(
    () =>
      queryString.parse(location.search).defaultMonth
        ? new Date(queryString.parse(location.search).defaultMonth as string)
        : undefined,
    [location]
  );

  useEffect(() => {
    if (
      ((showTimeline === true && showDefaultMonth === true) || (showTimeline === false && showDefaultMonth === true)) &&
      defaultMonth !== undefined
    ) {
      setSelectedDate(defaultMonth);
      setSelectedEndDate(moment(defaultMonth).add(1, 'month').toDate());
    } else {
      console.log('date', timelineLimits.to.toDate());

      const date = moment(timelineLimits.to.toDate().setDate(1)).toDate();
      setSelectedDate(moment(date.setHours(0, 0, 0, 0)).toDate());
      setSelectedEndDate(moment(date.setHours(0, 0, 0, 0)).add(1, 'month').toDate());
    }
  }, [showTimeline, showDefaultMonth, defaultMonth, timelineLimits]);

  return (
    <div>
      <Legend />
      <LangButton />
      <ShareButton />
      <Filter showData={showData} setShowData={setShowDataType} />
      {showTimeline &&
        (downXs ? (
          <MonthPicker selectedDate={selectedDate} setSelectedDate={setDisplayDate} />
        ) : (
          <Timeline selectedDate={selectedDate} setSelectedDate={setDisplayDate} />
        ))}
      <MapView
        id={'map'}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        initialViewState={{
          longitude: 4.3639482,
          latitude: 50.85156,
          zoom: 12
        }}
        style={{ height: '100vh', width: '100vw' }}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      >
        {filteredData.map((station, id) => {
          return <MarkerList key={id} station={station} selectedDate={selectedDate} endDate={selectedEndDate} />;
        })}
        <NavigationControl position={'top-left'} />
      </MapView>
    </div>
  );
};

export default withLocale(Map);
