import { FC } from 'react';

// Common
import Page from 'app/common/page';

// Material
import { Container, Paper, makeStyles } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    width: '100%',
    height: '100%'
  },
  paper: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2)
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'unset'
  }
}));

const Dashboard: FC = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Tableau de bord">
      <Container className={classes.container} maxWidth={false}>
        <Paper className={classes.paper}>
          <iframe
            className={classes.iframe}
            src={`${window.location.origin}/map?showTimeline=true`}
            title="Les chercheurs d'air - Dioxyde d'azote"
          ></iframe>
        </Paper>
      </Container>
    </Page>
  );
};

export default Dashboard;
